@import "../../styles/colors.scss";

.mstudio-toggle-state-button{

    button{
        border: 1px solid #cfcfcf;
        border-left: 0;
        padding: 5px 15px;
        font-size: 9pt;

        $radius: 2px;
        
        &:first-child{
            border-left: 1px solid #cfcfcf;
            border-radius: $radius 0 0 $radius;
        }

        &:last-child{
            border-radius: 0 $radius $radius 0;
        }

        &:hover{
            background-color: rgba(0,0,0,0.1);   
        }

        &.sel{
            background-color: $primary;
            color: $primary-fg;
        }
    }
}