@import "../../styles/colors.scss";

$color1: #64c8ff;

.edit-wrapper{
    &.selected{
        //border: 2px solid $primary;

        border: 2px solid $color1;
        color: #202020;
        font-size: 9pt;
        .buttons{
            position: absolute;
            
            margin-left: 15px;
            //margin-top: -27px;

            //top: 3px;
            //right: 3px;
            background-color: $color1;
            color: #202020;
            text-align: right;
            .btn{
                &:not(.transparent){
                    background-color: rgba(255,255,255,.2);
                    //border-bottom: 1px solid rgba(255,255,255,.4);
                    //border-right: 1px solid rgba(255,255,255,.4);
                }
                display: inline-block;
                padding: 3px 5px;
                margin: 1px;
                user-select: none;
                &:hover{
                    background-color: #202020;
                    color: #efefef;
                }
            }
            .label{
                //pointer-events: none;
                user-select: none;
                display: inline-block;
                padding: 3px 7px;
                color: rgba(0,0,0,0.6);
                font-size: 8pt;
            }
        }
    }

    
}

.edit-wrapper-menu{
    background-color: $color1;
    border: 1px solid rgba(0,0,0,0.3);
    box-shadow: -5px 5px 5px #afafaf;
    color: #202020;
    font-size: 9pt;

    .menu-item{
        position: relative;
        user-select: none;


        padding: 3px 10px;
        &:not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
        padding-left: 25px;
        i{
            position: absolute;
            left: 9px;
            top: 5px;
        }

        &.t8{
            font-size: 8pt;
            padding-left: 10px;
            user-select: unset;
        }

        &:not(.no-hover,.disabled){
            &:hover{
                background-color: rgba(255,255,255,0.2);
            }
        }

        &.disabled{
            color: rgba(0,0,0,0.5)
        }
    }

    .menu-item-group{
        padding: 1px 0;
        &:not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,0.3);
            
        }
    }

}

    