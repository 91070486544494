.mstudio-v-dock-layout{
    display: grid;
    //overflow: hidden;
    min-width: 100px;

    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: 'top' 'center' 'bottom';
    >.top{ grid-area: top; overflow: hidden }
    >.center{ grid-area: center; overflow: hidden }
    >.bottom{ grid-area: bottom; overflow: hidden }
}