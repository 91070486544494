@import "../../../../styles/colors.scss";

.edit-agg-items{
    //position: absolute;
    //left: 0;
    //top: 0;
    width: 1000px;
    height: 500px;
    border: 1px solid #c0c0c0;
    font-size: 9pt;
}