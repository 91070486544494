.logic-inspector{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    .title{
        font-size: 12pt;
        padding: 10px 20px 0 20px;
    }

    .top{
        font-size: 12px;
        //border: 1px dashed red;
    }

    .center{
        //border: 1px dashed blue;
        overflow-y: scroll;
        padding: 0px 20px 10px 20px;
    }

}