@import "../../styles/colors.scss";

.pipeline-stage{
    border: 1px solid #cfcfcf;
    margin-bottom: 5px;

    &.dragging{
        background-color: rgba(0,0,0,0.1);
    }

    .stage-header{
        border-bottom: 1px solid #cfcfcf;
        user-select: none;
        

        display: grid;
        grid-template-columns: min-content 1fr min-content;
        grid-template-areas: 'section1 section2 section3';


        .toggle-on{
            color: $primary;
        }
        .toggle-off{
            color: #808080;
        }

        .section1{
            grid-area: section1;
            white-space: nowrap;
            text-overflow: clip;
        }

        .stage-label{
            grid-area: section2;
            margin-left: 5px;
            display: flex;
            align-items: center;
            
            .input-stage-label{
                border: 0;//1px solid rgba(0,0,0,0.1);
                width: 100%;                
                font-size: 8pt;
            }
        }

        .section3{
            grid-area: section3;
            white-space: nowrap;
            text-overflow: clip;
            display: inline-block;

            a{
                color: #404040;
            }
        }
        
    }

    .stage-collapser{
        border-top: 1px solid #efefef;
        padding: 3px;
        text-align: center;

        &:hover{
            background-color: $tertiary;
            color: $tertiary-fg;
        }
    }

    .stage-data{
        padding: 10px;

        &.disabled{
            opacity: 0.25;
        }
    }
}
