

$z-index-modal: 999;
$z-index-left-bar: 5;
$z-index-left-menu: 20; //1;
$z-index-left-menu-overlay: 21; //2;
$z-index-compute-cover: 10;
$z-index-export-actions: 11;

$z-index-right-menu: 20; //1;

$z-index-bottom-bar: 30;