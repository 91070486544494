@import "../../styles/colors.scss";

.data-table{
    //position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    

    &.auto-height{
        position: static;
        height: unset;
        overflow-y: hidden;
    }

    &.no-select{
        user-select: none;
    }

    .col-cb-select{
        text-align: center;
    }

    table{
        border-spacing: 0;
        table-layout: fixed;
    }
    
    th{
        user-select: none;
        font-weight: normal;
        //background-color: $gray2;
        background-image: linear-gradient(#ffffff, #fafafa);
        padding: 5px 20px 5px 10px;
        //border-bottom: 1px solid #808080;
        border-bottom: 2px solid $primary;
        position: sticky;
        top: 0;
        text-align: left;
        vertical-align: bottom;

        &.nested{
            top: 25px; // ??
        }

        &.cat{
            background-color: #ffffff;
            background-image: none;
            border-bottom: 0;
            //border-left: 2px solid $primary;
        }

        .th-content{
            width: 100%;
            //border: 1px dashed teal;
            display: inline-flex;
            align-items:flex-end;
            .th-glyph{
                //border: 1px dashed red;
                padding-right: 5px;
            }

            .th-label{
                flex-grow: 1;
            }

            .th-sort-ind{
                font-size: 8pt;
            }
        }

        &:hover{
            &:not(.cat){
                background-image: none;
                background-color: $primary;
                color: $primary-fg;
            }
        }
    }

    tr{
        &.row-hover{
            &:hover{
                .cell{
                    background-color: $primary;
                    color: $primary-fg;
                }
            }
            &.sel{
                .cell{
                    background-color: $primary;
                    color: $primary-fg2;
                }
            }
        }

    }

    .cell{
        padding: 5px 20px 5px 10px;
        &.even-padding{
            padding: 5px 10px 5px 10px;
        }
        border-bottom: 1px solid $gray2;
        //text-align: right;
        display: table-cell;

        &.section-label{
            font-weight: 600;
            border-bottom: 1px solid #cfcfcf;
        }

        white-space: nowrap;
        text-overflow: clip;

        &.val-obj{
            //border: 1px dashed #cfcfcf;
            border-right: 1px solid #efefef;
            text-align: center;

            .n-size{
                font-size: 7pt;
                float: right;
                &::before{
                    content: "n="
                }
                
                color: peru;
            }
        }

        &.percent-bar{
            display: grid;
            grid-template-columns: 1fr 40px;
            grid-template-areas: 'bar value';
            >.bar-cell{
                //border: 1px dashed red;
            }
            >.value-cell{
                //border: 1px dashed purple;
                text-align: right;
            }
        }

        &.scaled-bar{
            line-height: 20px;
            .bar-cell{
            }
            .value-cell{
                padding-left: 5px;
            }
        }

        &.comet, &.dumbbell{
            padding: 5px 10px;
        }


        &.label-obj{
            //border: 1px dashed violet;
            //text-align: left !important;

            .markup{
                margin-left: 5px;
                font-size: 7pt;
                //color: violet;
                color: tan;
            }
        }

        &.generic-obj{
            
        }

        &:hover{
            background-color: $primary;
            color: $primary-fg;
        }

    }
}