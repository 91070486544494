.schema-diff-tool{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-columns: 1fr 1fr 30px;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas:
        'left-sel right-sel .'
        'options options .'
        'main-diff main-diff main-diff';

    .left-selector{
        grid-area: left-sel;
    }

    .right-selector{
        grid-area: right-sel;
    }

    .options{
        grid-area: options;
        //border-bottom: 1px solid #cfcfcf;
        padding: 0;
    }

    .main-diff{
        grid-area: main-diff;
        position: absolute;
        left:0;
        top: 0;
        width:100%;
        height:100%;

        .message{
            position: absolute;
            left:0;
            top: 0;
            width:100%;
            height:100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 20px;
        }
    }

    .schema-selector{
        border-bottom: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;
        padding: 10px;
        .select-ds{
            display: block;
            margin-bottom: 10px;
            width: 100%;
            //margin: 0px 10px;
        }
        .select-revision{
            display: block;
            width: 100%;
        }
    }

}