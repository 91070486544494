@import "../../styles/colors.scss";

.edit-content-item{

    .item-id{
        text-align: center;
        font-size: 8pt;
        color: #808080;
        //text-transform: uppercase;
        margin-bottom: 2px;
    }

    .item-ctype{
        text-align: center;
        font-size: 8pt;
        color: #808080;
        //text-transform: uppercase;
        margin-bottom: 10px;
    }

    
    .category{
        $c1: lighten($secondary, 20%);    

        border: 1px solid $c1;
        border-top: 0;
        margin-bottom: 10px;

        >.name{
            background-color: $c1;
            color: $secondary-fg;
            //font-weight: bold;
            padding: 5px 10px;
            
        }
    }
 
    .setting{
        border-bottom: 1px solid #cfcfcf;
        padding: 5px 10px;

        display: flex;
        align-items: center;

        &:hover{
            background-color: #efefef;
        }

        >.name{
            flex-grow: 1;
            display: block;
            font-weight: bold;
            margin-bottom: 5px;
        }
        .value{
            margin-left: 10px;
            display: block;
            
            // .number-box{
            //     display: inline-block;
            //     border: 1px solid #cfcfcf;
            //     background-color: #ffffff;
            //     padding: 5px 10px;
            // }
            
        }
        .help{
            margin-left: 5px;
            display: block;
        }
    }
    
}