@import "../styles/colors.scss";

select{
    border: 1px solid #cfcfcf;
    padding: 5px;

    &.full-width{
        width: 100%;
    }
}

input{
    border: 1px solid #cfcfcf;
    padding: 5px;

    &.full-width{
        width: 100%;
    }

    &.fa{
        font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif, 'Font Awesome 5 Pro';
    }
    
}

textarea{
    border: 1px solid #cfcfcf;
    padding: 5px;
    resize: none;

    &.full-width{
        width: 100%;
    }

    &.two-lines{
        height: 60px;
    }

}

.h-dock{
    //border: 1px dashed teal;
    display: grid;
    grid-template-columns: min-content min-content 1fr min-content min-content;
    grid-template-areas: 'left left2 center right2 right';

    >.left{
        grid-area: left;
        &.v-center{
            display: flex;
            align-items: center;
        }
    }

    >.left2{
        grid-area: left2;
        &.v-center{
            display: flex;
            align-items: center;
        }
    }

    >.center{
        grid-area: center;
        &.v-center{
            display: flex;
            align-items: center;
        }
    }

    >.right2{
        grid-area: right2;
        &.v-center{
            display: flex;
            align-items: center;
        }
    }

    >.right{
        grid-area: right;
        &.v-center{
            display: flex;
            align-items: center;
        }
    }

    

}

.flex-vcenter{
    display: flex;
    align-items: center;
}

.form-group-header{
    font-size: 9pt;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-weight: 500;
}

hr{
    border: 0;
    border-top: 1px solid #cfcfcf;

    margin-top: 15px;
    margin-bottom: 15px;
    
}

.debug{
    border: 1px dashed teal;
}



.full-dock{
    display: grid;
    grid-template-rows: min-content min-content 1fr min-content min-content;
    grid-template-columns: min-content min-content 1fr min-content min-content;
    grid-template-areas: 
        'top top top top top'
        'top2 top2 top2 top2 top2'
        'left left2 center right2 right'
        'left left2 center right2 right'
        'bot2 bot2 bot2 bot2 bot2'
        'bot bot bot bot bot';
    &.lr-priority{
        grid-template-areas: 
        'left left2 top right2 right'
        'left left2 top2 right2 right'
        'left left2 center right2 right'
        'left left2 center right2 right'
        'left left2 bot2 right2 right'
        'left left2 bot right2 right';
    }
        
    >.center{ grid-area: center; }
    
    >.left{ grid-area: left; }
    >.right{ grid-area: right; }
    >.top{ grid-area: top; }
    >.bottom{ grid-area: bot; }
    >.left2{ grid-area: left2; }
    >.right2{ grid-area: right2; }
    >.top2{ grid-area: top2; }
    >.bottom2{ grid-area: bot2; }
    

    
}


.form1{
    label{
        font-size: 11pt;
    }
    .label-line{
        display: block;
        margin-bottom: 5px;
    }
    .value-line{
        display: block;
        margin-bottom: 15px;
        &.no-margin{
            margin-bottom: 0;
        }
    }
    .help-info{
        font-size: 9pt;
        font-style: italic;
        color: #808080;
    }
    input[type=text]{
        border: 1px solid #cfcfcf;
        border-radius: 0px;
        padding: 8px;
        font-size: 10pt;
        width: 350px;

        &.full-width{
            width: 100%;
        }
    }

    textarea{
        resize: none;

        &:focus{
            outline: 0;
        }
    }

    .code{
        font-family: 'Courier New', Courier, monospace;
    }
}


.progress-bar{
    display: block;
    border: 1px solid #cfcfcf;

    >.progress{
        height: 24px;
        background-color: $primary;
    }
}