@import "../../../styles/colors.scss";

.etl-node-swatches{
    //border: 3px solid red;
    display: flex;
    flex-direction: column;
    gap: 2px;

    .swatch-group{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 1px;
        
        .swatch{
            display: inline-block;
            width: 15px;
            height: 15px;
            //border: 1px solid #404040;
            border: 0;
            
            display: inline-flex;
            padding: 0;
            font-size: 8px;
            align-items: center;
            justify-content: center;
            //text-transform: uppercase;
        }
    }
}

.etl-node{
    position: relative;
    padding: 2px;
    //border: 1px dashed teal;
    cursor: grab;
    z-index: 1;

    

    .etl-content{
        padding: 0px;
        width: 100%;
        height: 100%;
        background-color: rgba(255,255,255,0.7);// #ffffff;
        border: 1px solid #cfcfcf;
        box-shadow: 0 2px 6px rgba(0,0,0,0.2);
        border-radius: 5;

        display: grid;
        grid-template-columns: min-content 1fr min-content;
        grid-template-areas: 'icon label actions';

        >.icon{ 
            grid-area: icon;
            display: flex;
            align-items: center;
            justify-content: center;
            color: teal;
            padding: 5px 0px 5px 15px;
        }
        >.label{ 
            grid-area: label;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;

            //white-space: nowrap;
            //text-overflow: ellipsis;
            overflow: hidden;     
        }

        >.actions{ 
            grid-area: actions;
            padding: 5px;
            cursor: pointer;
            //border: 1px solid blue;
        }
        
    }

    &.sel{
        .etl-content{
            border: 1px solid $primary;
        }
    }

    .input-pin{
        display: inline-block;
        position: "absolute";
        width: 20px;
        height: 16px;
        background-color: #0078BE;
        color: #ffffff;
        border-radius: 0 5px 5px 0;
        color: "#ffffff";
        //font-size: 9;
        padding: 0 0 2px 4px;

        &:hover{
            background-color: orchid;
        }
    }

    .output-pin{
        display: inline-block;
        position: "absolute";
        width: 20px;
        height: 16px;
        background-color: #0078BE;
        color: #ffffff;
        border-radius: 0 5px 5px 0;
        color: "#ffffff";
        //font-size: 9;
        padding: 0 0 2px 4px;

        &:hover{
            background-color: orchid;
        }
    }
}