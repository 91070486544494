@import "../../styles/colors.scss";

.project-home{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    // display: flex;
    // align-items: center;
    // justify-content: center;

    overflow-y: auto;
    padding: 20px;

    background-color: #efefef;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYlWP48uXL/+fPn2PFX758+c8AY2CTfP78OUQBuiJkNgO6LnTTiFeA1wq8jiTkTQB2Ne9x04gUuAAAAABJRU5ErkJggg==") repeat;

    $box-height: 270px;

    >.tile-container{

        margin: 0 auto;

        display: grid;
        grid-template-columns: 33% 33% 33%; //1fr 1fr 1fr;
        grid-template-rows: $box-height $box-height;
        row-gap: 10px;
        column-gap: 10px;
        max-width: 1400px;

        @media screen and (max-width: 1000px){
            grid-template-columns: 1fr 1fr;
            grid-template-rows: $box-height $box-height $box-height;
        }

        @media screen and (max-width: 750px){
            grid-template-columns: 1fr;
            grid-template-rows: repeat(6, $box-height);
        }
                
        >.tile{

            display: grid;
            grid-template-rows: min-content 1fr min-content;
            grid-template-areas: 'title' 'details' 'action';
            //border-radius: 0 0 10px 10px;
            overflow: hidden;

            &.white-bg{
                background-color: #ffffff;
                //background-color: #404040;
                border: 1px solid #cfcfcf;
                box-shadow: 4px 4px 6px rgba(0,0,0,0.1);
            }

            &.gray-bg{
                //background-color: rgba(255,255,255,0.5);
                background-color: rgba(247,247,247);
                //border-left: 3px solid #cfcfcf;
                //border: 1px solid #cfcfcf;
                //background-image: linear-gradient(to bottom right, #efefef, #afafaf);

                box-shadow: 4px 4px 6px rgba(0,0,0,0.05);
            }

            &.pad{
                padding: 20px;
            }

            .title{
                grid-area: title;
                font-size: 14pt;
                margin-bottom: 10px;
                //border-bottom: 1px solid #cfcfcf;
                

                .supacool{
                    display: inline-block;
                    background-color: $primary;
                    color: $primary-fg;
                    padding: 2px 15px;
                }

                .help-link{
                    font-size: 9pt;
                    float: right;
                    padding: 3px 6px;
                    //border: 1px solid red;
                    a{
                        color: #afafaf;
                        text-decoration: none;
                    }
                }
            }

            .details{
                grid-area: details;
                //border: 1px solid red;
                overflow: hidden;

                .client{

                }

                .project-date{
                    margin-top: 5px;
                }

                .project-settings{
                    text-decoration: none;
                    color: #404040;
                    user-select: none;
                    display: inline-block;
                    margin-top: 10px; margin-right: 5px;
                    padding: 4px 10px;
                    border-left: 4px solid $primary;
                    background-color: #dfdfdf;
                    //margin-left: -10px;
                    &:hover{
                        background-color: #404040;
                        color: #efefef;
                        //color: $primary-fg;
                    }
                }
            }

            .loading{
                padding: 5px 0;
            }
            
            .link-row{
                grid-area: action;
                display: block;
                padding: 5px 0px;
                text-decoration: none;
                color: #202020;
                &:hover{
                    //background-color: #efefef;
                    color: $primary;
                }
            }

            .item-row{
                display: block;
                padding: 4px 10px;
                user-select: none;
                text-decoration: none;
                color: #202020;
                border-bottom: 1px solid #cfcfcf;

                white-space: nowrap;
                text-overflow: clip;

                &:last-child{
                    border-bottom: 0;
                }
                
                &:hover{
                    background-color: $primary;
                    color: $primary-fg;
                }
            }

            .edit-link{
                display: block;
                margin-top: 10px;
                text-decoration: none;
                color: $primary;
                //text-align: center;
            }

            .buttons-grid{

                // display: flex;
                // flex-wrap: wrap;
                // align-items: flex-start;
                // justify-content: flex-start;
                //row-gap: 5px;
                //column-gap: 5px;


                .button{
                    
                    display: inline-flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    text-decoration: none;
                    row-gap: 5px;
                    margin: 0 2px 2px 0;
                    
                    //column-gap: 5px;
                    background-color: rgba(240,240,240,.2);

                    width: 90px;
                    height: 60px;
                    //border: 1px solid #cfcfcf;
                    //background-color: #fafafa;
                    color: #202020;

                    >i{
                        display: block;
                        color: #202020;
                    }

                    &:hover{
                        background-color: $primary;
                        color: $primary-fg;
                    }

                }
            }

        }

    }


    

    
}