@import "../../styles/colors.scss";

.select-cgroups-dlg{
    
    padding: 0;
    font-size: 9pt;
    //font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

    display: grid;
    width: 1000px;
    height: 500px;
    grid-template-columns: 300px 1fr min-content 1fr;
    grid-template-rows: min-content min-content min-content min-content 1fr;
    grid-template-areas: 
        'header header header header'
        'title1 title1 . title2'
        'selcat list1-actions arrow list2-actions'
        'fgsearch list1 arrow list2'
        'fglist list1 arrow list2'
    ;

    .header{
        grid-area: header;
        padding: 10px 20px;
        font-weight: 500;
        
        //border-bottom: 1px solid #cfcfcf;
    }

    .title1{
        grid-area: title1;
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;
        font-weight: 500;
        text-transform: uppercase;
    }

    .title2{
        grid-area: title2;
        padding: 10px;
        border-bottom: 1px solid #cfcfcf;
        font-weight: 500;
        text-transform: uppercase;
        margin-left: 10px;
    }

    .select-category{
        padding: 10px 10px 0 0;
        grid-area: selcat;
        //border-bottom: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;

        >select{
            width: 100%;
        }
        
    }

    .fgsearch{
        grid-area: fgsearch;
        border-right: 1px solid #cfcfcf;
        padding-right: 10px;
        padding-top: 0px;
        padding-bottom: 10px;
        border-bottom: 1px solid #cfcfcf;
        position: relative;

        .tb-search{
            width: 100%;
            border: 1px solid #dfdfdf;
            padding-right: 35px;
        }
        .btn-cancel-search{
            background-color: transparent;
            position: absolute;
            right: 10px;
            top: 0;
            height: calc(100% - 10px);
            width: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover{
                background-color: rgba(0,0,0,0.1);
            }
        }
    }

    .fglist{
        grid-area: fglist;
        overflow-y: auto;
        overflow-x: hidden;
        border-right: 1px solid #cfcfcf;
        border-left: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;

        >.item{
            padding: 7px 20px;
            border-bottom: 1px solid $gray2;
            user-select: none;
            white-space: nowrap;
            text-overflow: clip;

            &.sel{
                background-color: $primary;
                color: $primary-fg;
            }

            &:hover{
                background-color: $primary-med;
                color: $primary-fg;
            }
        }
    }

    .list1-actions{
        grid-area: list1-actions;
        padding: 10px 0px 10px 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #cfcfcf;

        .stretch-spacer{
            flex-grow: 1;
        }
    }

    .list1{
        grid-area: list1;
        //border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;
        overflow-y: auto;
        overflow-x: hidden;
        //border: 1px solid #cfcfcf;
        border-bottom: 1px solid #cfcfcf;

        >.item{
            padding: 5px 20px;
            border-bottom: 1px solid $gray2;
            user-select: none;
            position: relative;
            //border: 1px dashed teal;

            white-space: nowrap;
            text-overflow: clip;

            &.sel{
                background-color: $primary;
                color: $primary-fg;
            }

            &:hover{
                background-color: $gray2;

                &.can-select{
                    background-color: $primary-med;
                }
                
                //color: #ffffff;
            }
        }



    }

    .add-btn{
        
        background-color: $gray2;
        padding: 3px 10px;
        
        position: absolute;
        right: 5px;
        top: 2px;
        height: calc(100% - 4px);
        //width: 30px;

        display: inline-flex;
        align-items: center;
        justify-content: center;

        font-size: 8pt;
        color: #000000;
        user-select: none;

        &.margin-right{
            margin-right: 5px;
        }

        &.sel{
            background-color: $primary;
            color: $primary-fg;
        }

        &:hover{
            background-color: $primary-med;
            color: $primary-fg;
        }
    }

    .toggle-btn{
        display: inline-block;
        background-color: $gray2;
        padding: 7px 10px;
        float: right;
        font-size: 8pt;
        color: #000000;
        user-select: none;

        &.margin-right{
            margin-right: 5px;
        }

        &.sel{
            background-color: $primary;
            color: $primary-fg;
        }

        &:hover{
            background-color: $primary-med;
            color: $primary-fg;
        }
    }

    .arrow-section{

        grid-area: arrow;
        padding: 10px 0 10px 10px;
        //border: 1px dashed teal;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        .arrow{
            font-size: 11px;
            padding: 7px 10px;
            border: 1px solid #cfcfcf;

            &:hover{
                background-color: $primary;
                color: $primary-fg;
            }
        }
    }

    .list2-actions{
        grid-area: list2-actions;
        padding: 10px;
        padding-right: 0;
        margin-left: 10px;       
    }

    .list2{
        grid-area: list2;
        overflow-x: hidden;
        overflow-y: auto;
        margin-left: 10px;
        border: 1px solid #cfcfcf;
        

        >.item{
            padding: 5px 20px;
            border-bottom: 1px solid $gray2;
            user-select: none;
            position: relative;

            white-space: nowrap;
            text-overflow: clip;
            

            &.sel{
                background-color: $primary;
            }

            &:hover{
                background-color: $gray2;
                //color: #ffffff;
            }
        }
    }

 
}