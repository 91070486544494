@import "../../styles/colors.scss";

.project-settings{
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'top' 'actions' 'main';

    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;


    .top-header{
        grid-area: top;
        //display: none;
        
        //text-align: right;
        font-size: 9pt;
        padding: 10px;
        border-bottom: 1px solid #cfcfcf;

        display: flex;
        flex-direction: row;

        .left-section{
            //border: 1px dashed blue;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-grow: 1;
            >.title{
                
                font-size: 12pt;
            }
            >.back-link{
                margin-left: 10px;
                color: $primary;
                cursor: pointer;
                text-decoration: none;
                &:hover{
                    text-decoration: underline;
                }
            }
        }

        .right-section{
            //float: right;
            //border: 1px dashed red;

            button{
                border: 1px solid #cfcfcf;
                padding: 5px 15px;
                &:hover{
                    background-color: $primary;
                    color: $primary-fg;
                }
            }

            .dirty-label{
                font-size: 8pt;
                text-transform: uppercase;
                color: #808080;
                margin: 0 10px;
            }
        }

        .view-button{
            user-select: none;
            display: inline-block;
            background-color: $gray2;
            padding: 5px 8px;
            margin-right: 5px;

            &.sel{
                background-color: $primary;
                color: #ffffff;
            }
        }
    }

    .actions-header{
        grid-area: actions;
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;
    }

    .main{
        grid-area: main;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        overflow: hiden;

        >.top{
            padding-top: 5px;
        }


    }


}