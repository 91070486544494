@import "../../styles/colors.scss";

.select-pipeline-stage{
    
    height: 100%;
    overflow-y: scroll;
    

    >.items{
            
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        user-select: none;

        align-items: center;
        justify-content: center;
        padding: 5px;
        gap: 5px;
        
        
        //max-height: 300px;

        //width: 800px;
        //max-width: 100%;

        .item{
            //width: 200px;
            //flex-grow: 1;
            width: 210px;
            border: 1px solid #cfcfcf;
            padding: 10px;
            font-size: 12px;

            display: grid;
            grid-template-columns: min-content 1fr min-content;
            grid-template-areas: 'icon title help';

            &:hover{
                background-color: $primary;
            }

            .title{
                grid-area: title;
                display: flex;
                align-items: center;
            }

            .icon-box{
                grid-area: icon;
                
                font-size: 20px;
                margin-right: 10px;
                //border:1px dashed teal;
                
                display: flex;
                align-items: center;
                justify-content: center;

                width: 30px;
                height: 30px;
            }

            .description{
                display: none;
            }

            .help-link{
                grid-area: help;
                text-decoration: none;

                display: flex;
                align-items: center;
                justify-content: center;

                color: #cfcfcf;

                &:hover{
                    color: #202020;
                }

            }
        }

    }
}

