@import "../../styles/colors.scss";

.select-coll-entry-dlg{
    
    padding: 0;
    font-size: 9pt;

    display: grid;
    width: 900px;
    height: 500px;
    grid-template-columns: 250px 1fr min-content 1fr;
    grid-template-rows: min-content min-content min-content 1fr;
    grid-template-areas: 
        'header header header header'
        'title1 title1 . title2'
        'selcat list1-actions arrow list2-actions'
        'fglist list1 arrow list2'
    ;

    .header{
        grid-area: header;
        padding: 10px 20px;
        font-weight: 500;
        
        //border-bottom: 1px solid #cfcfcf;
    }

    .title1{
        grid-area: title1;
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;
        font-weight: 500;
        text-transform: uppercase;
    }

    .title2{
        grid-area: title2;
        padding: 10px;
        border-bottom: 1px solid #cfcfcf;
        font-weight: 500;
        text-transform: uppercase;
    }

    .select-category{
        padding: 10px 10px 10px 20px;
        grid-area: selcat;
        border-bottom: 1px solid #cfcfcf;

        >select{
            width: 100%;
        }
        
    }

    .fglist{
        grid-area: fglist;
        overflow-y: auto;

        >.item{
            padding: 5px 20px;
            border-bottom: 1px solid $gray2;
            user-select: none;

            &.sel{
                background-color: $primary;
            }

            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }
    }

    .list1-actions{
        grid-area: list1-actions;
        padding: 10px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid #cfcfcf;

    }

    .list1{
        grid-area: list1;
        border-left: 1px solid #cfcfcf;
        border-right: 1px solid #cfcfcf;
        overflow-y: auto;

        >.item{
            padding: 5px 20px;
            border-bottom: 1px solid $gray2;
            user-select: none;

            

            &.sel{
                background-color: $primary;
            }

            &:hover{
                background-color: $gray2;
                //color: #ffffff;
            }
        }
    }

    .add-btn{
        display: inline-block;
        background-color: $gray2;
        padding: 3px 10px;
        float: right;
        font-size: 8pt;
        color: #000000;
        user-select: none;

        &:hover{
            background-color: $primary;
            color: #ffffff;
        }
    }

    .arrow-section{
        display: none;
        visibility: collapse;

        grid-area: arrow;
        padding: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;

        .arrow{
            font-size: 11px;
            padding: 10px;
            border: 1px solid #cfcfcf;

            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }
    }

    .list2-actions{
        grid-area: list2-actions;
        padding: 10px;
    }

    .list2{
        grid-area: list2;
        overflow-y: auto;

        >.item{
            padding: 5px 20px;
            border-bottom: 1px solid $gray2;
            user-select: none;

            

            &.sel{
                background-color: $primary;
            }

            &:hover{
                background-color: $gray2;
                //color: #ffffff;
            }
        }
    }

 
}