@import "../../styles/colors.scss";

.edit-pipeline-with-preview{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    

    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-areas: 'stages results';

    >.stages-tab{
        position: absolute;
        left: 0;
        top: 0;
        background-color: red;
    }

    >.stages{
        grid-area: stages;
        position: relative;

        width: 400px;
        border-right: 1px solid #cfcfcf;

        >.title{
            padding: 5px 10px;
            font-size: 9pt;
            text-transform: uppercase;

            border-bottom: 1px solid #cfcfcf;

            .btn-save{
                margin-left: 10px;
            }

            .help{
                position: absolute;
                top: 0px;
                right: 30px;
                padding: 3px 5px;
                //border: 1px solid #cfcfcf;
                background-color: #ffffff;

                &:hover{
                    background-color: #404040;
                    color: #efefef;
                }
                //float: right;
            }

            .collapser{
                position: absolute;
                top: 2px;
                right: 5px;
                border: 1px solid #cfcfcf;
                background-color: #ffffff;

                &:hover{
                    background-color: #404040;
                    color: #efefef;
                }
                //float: right;
            }
        }
    }

    >.results{
        grid-area: results;
        position: relative;

        >.title{
            display: flex;
            flex-direction: row;
            

            border-bottom: 1px solid #cfcfcf;
            padding: 5px 10px;
            font-size: 9pt;
            

            .expander{
                margin-right: 5px;
                //padding: 0 10px;
                border: 1px solid #cfcfcf;
                background-color: #ffffff;
            }

            .text{
                //border: 1px dashed blue;
                flex-grow: 1;
                text-transform: uppercase;
            }
           

            .actions{
                display: flex;
                flex-direction: row;
                gap: 10px;

                .primary-color{
                    color: $primary;
                }
                .off-color{
                    color: #909090;
                }

                //border: 1px dashed gold;

            }
        }

        >.center{
            position: relative;

            .message{
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                //border: 5px dashed purple;
                padding: 0;

                display: flex;
                align-items: center;
                justify-content: center;

                background-color: rgba(255,255,255,0.5);
                //background-color: red;
                font-size: 9pt;
                font-weight: 400;

                .btn-dismiss{
                    user-select: none;
                    font-size: 8pt;
                    color: #202020;
                    padding: 4px 10px;
                    &:hover{
                        color: #ffffff;
                    }
                }

                i{
                    margin-right: 5px;
                }

                .pop{
                    width: 100%;
                    //background-color: white;
                    background-color: $primary;
                    color: $primary-fg;
                    padding: 15px 20px;
                    text-align: center;
                }

                &.error{
                    i{
                        --fa-primary-color: red;
                        --fa-secondary-color: yellow;// #aa0000;
                        --fa-secondary-opacity: 1.0;
                    }
                    //color: yellow;
                    .pop{
                        color: yellow;
                    }
                }

            }
        }
    }

    
}