@import "../styles/zIndex.scss";
@import "../styles/colors.scss";

h4{
    margin: 0;
    padding: 0;
    font-weight: normal;
    font-size: 13pt;    
}

p{
    font-size: 10pt;
    font-weight: normal;
    margin-bottom: 20px;
    margin-top: 0;
    line-height: 20px;
}


.input-label1{
    padding: 0 0 5px 0;
    font-size: 10pt;
    font-weight: 500;

    .help-text{
        font-weight: normal;
        font-size: 9pt;
        color: #404040;
        padding: 5px 0;
    }
}

.fill-parent{
    width: 100%;
    height: 100%;
}

.fill-parent-absolute{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}


.input-box1{
    padding: 0 0 20px 0;

    .text1{
        width: 100%;
        max-width: 400px;
        padding: 5px 10px;
        border: 1px solid #cfcfcf;
        border-radius: 2px;
        &::placeholder{
            font-style:italic;
            color: #afafaf;
        }
    }

    .textarea1{
        width: 100%;
        height: 80px;
        resize: none;
        border: 1px solid #cfcfcf;
        border-radius: 2px;
        font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        font-size: 10pt;
        padding: 5px 10px;
    }
}

.btn{
    user-select: none;
    text-decoration: none;;

    &.margin-right{
        margin-right: 5px;
    }

    &.margin-left{
        margin-left: 5px;
    }

    &.del{
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        width: 20px;
        height: 20px;
        position: absolute;
        right: -5px;
        top: -5px;
    }

    &.action{

        background-color: $primary;
        border-radius: 15px;
        color: #ffffff;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        
        padding: 5px 20px;
        font-size: 9pt;

        &.smaller{
            font-size: 8pt;
            padding: 4px 15px;
            
        }

        &.small{
            font-size: 8pt;
            padding: 2px 10px;
            
        }

        &.no-text{
            padding: 5px 6px;
        }
        
        .glyph{
            margin-right: 5px;
            font-size: 9pt;
            color: #ffffff;
        }

        &:hover{
            //background-color: #000000;
            background-color: lighten($primary, 10%);
            //color: #dfdfdf;
            .glyph{
                color: #ffffff;
            }
        }

        &.disabled{
            background-color: #afafaf;
            color: #808080;
            
        }
    }

    &.action-light{

        background-color: "#ffffff";
        &.bg2{
            background-color: #efefef;
        }
        color: #202020;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        
        padding: 5px 15px;
        font-size: 9pt;

        &.no-text{
            padding: 5px 6px;
        }
        
        .glyph{
            margin-right: 5px;
            font-size: 9pt;
            color: #202020;
        }

        &:hover{
            //background-color: #000000;
            background-color: darken(#ffffff, 10%);
            //color: #dfdfdf;
            .glyph{
                color: #000000;
            }
        }

        &.disabled{
            //background-color: #afafaf;
            color: #afafaf;
            
        }
    }

    &.toggle{

        background-color: #d4d4d4;
        //border-radius: 15px;
        color: #202020;
        border: 0;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        
        padding: 5px 20px;
        font-size: 9pt;

        &.no-text{
            padding: 5px 6px;
        }

        &.small{
            padding: 3px 10px;
            font-size: 8pt;
        }

        &.light{
            background-color: #e4e4e4;
        }
        
        .glyph{
            margin-right: 5px;
            font-size: 9pt;
            color: #ffffff;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
            .glyph{
                color: #ffffff;
            }
        }

        &:hover{
            background-color: #000000;
            color: #afafaf;
            .glyph{
                color: #ffffff;
            }
        }

        &.disabled{
            background-color: #afafaf;
            color: #808080;
            
        }
    }

    &.toggle-small{

        background-color: #d4d4d4;
        //border-radius: 15px;
        color: #202020;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        flex-direction: row;
        
        padding: 3px 10px;
        font-size: 8pt;

        &.no-text{
            padding: 5px 6px;
        }

        &.small{
            padding: 3px 10px;
            font-size: 8pt;
        }

        &.light{
            background-color: #e4e4e4;
        }
        
        .glyph{
            margin-right: 5px;
            font-size: 9pt;
            color: #ffffff;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
            .glyph{
                color: #ffffff;
            }
        }

        &:hover{
            background-color: #000000;
            color: #afafaf;
            .glyph{
                color: #ffffff;
            }
        }

        &.disabled{
            background-color: #afafaf;
            color: #808080;
            
        }
    }

    &.icon{
        width: 28px;
        height: 28px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        // border: 1px dashed red;
        // background-color: yellow;

        &.small{
            width: 20px;
            height: 20px;
            font-size: 10px;
        }

        &.border{
            border: 1px solid #cfcfcf;
        }
        
        &.sel{
            background-color: $primary;
            color: #ffffff;
            .glyph{
                color: #ffffff;
            }
        }

        &:not(.disabled){
            &:hover{
                background-color: rgba(0,0,0,0.2);
            }
        }
    }

    &.icon-text{
        border: 1px solid #cfcfcf;
        padding: 5px;
        display: flex;
        flex-direction: column;
        align-items: center;
        // &.inline{
        //     display: inline-flex;
        // }
        .text{
            display: block;
        }
        .icon{
            display: block;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
        }
    }

    &.cancel{
        background-color: #afafaf;
    }

}

.empty-space1{
    background-color: $gray2;
}

.page1{
    background-color: #ffffff;
    padding: 20px;
    border: 1px solid #cfcfcf;

    &.margin2{
        margin: 20px;
    }

}

.message-block{
    display: block;
    background-color: $gray2;
    padding: 10px;
    margin-bottom: 15px;
    
    &.error{
        color: red;
    }
}






.popup-content {
    margin: auto;
    background: rgb(255, 255, 255);
    //width: 50%;
    //padding: 10px;
    padding: 0;
}

.popup-arrow {
    color: rgb(255, 255, 255);
}

[role='tooltip'].popup-content {
    width: 200px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
    background: rgba(0, 0, 0, 0.2);
}

[data-popup='tooltip'].popup-overlay {
    background: transparent;
}

.nowrap{
    white-space: nowrap;
    text-overflow: clip;
    overflow: clip;
}

.popup-menu{
    user-select: none;
    margin: 0;
    padding: 0;
    //border: 1px solid #cfcfcf;
    list-style-type: none;

    &.max-height-small{
        max-height: 300px;
        overflow-y: auto;
    }

    .menu-group{
        &:not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,0.5);
        }
    }

    li,.menu-item,.separator{
        display: block;
        //border-bottom: 1px solid #cfcfcf;
        &:not(:last-child){
            border-bottom: 1px solid rgba(0,0,0,0.1);
        }
        font-size: 9pt;
        text-decoration: none;
        color: #404040;
        &.disabled{
            color: rgba(64,64,64,0.5);
        }

        &.nowrap{
            white-space: nowrap;
            text-overflow: clip;
            overflow: clip;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
        }
        
        &:not(.separator){
            padding: 10px 20px;
            &:not(.no-hover){
                &:hover{
                    background-color: darken($primary,10%);
                    color: #ffffff;
                }
            }
        }

        &.mini{
            padding: 5px 10px;
        }

        &.separator{
            border-bottom: 1px solid #a0a0a0;
            //margin-top: 3px;
            margin-bottom: 3px;
        }

        .icon{
            float: right;
        }

        
    }

    // &.ignore-style-alt1{
    //     background-color: lighten($secondary, 20);
    //     .menu-item{
    //         background-color: rgba(0, 0, 0,0.2);
    //         color: $secondary-fg;
    //         //border-bottom: 1px solid red;

    //         &.sel{
    //             background-color: $primary;
    //             color: #ffffff;
    //         }
    //     }
    // }
    
}



.tab-section{
    display: block;
    margin: 5px 20px 10px 20px;
    padding: 0;
    border-bottom: 2px solid #cfcfcf;
    //text-align: center;

    &.reset-margin{
        margin: 5px 0 10px 0;
    }

    &.no-margin{
        margin: 0;
    }
       

    .tab{
        text-decoration: none;
        color: #202020;
        user-select: none;
        display: inline-block;
        padding: 5px 15px 5px 15px;
        margin: 0 0 -2px 0;
        border-bottom: 2px solid transparent;

        &:hover{
            background-color: #404040;
            color: #ffffff;
        }
                    
        &.sel{
            border-bottom: 2px solid #202020;
        }
    }
}

.tab-section2{
    display: block;
    margin: 0;
    padding: 0;

    .tab{
        text-decoration: none;
        background-color: #efefef;
        color: #202020;
        user-select: none;
        display: inline-block;
        padding: 5px 15px 5px 15px;
        margin: 0 0 -2px 0;
        border-bottom: 2px solid transparent;

        &:not(:last-child){
            border-right: 1px solid #cfcfcf;
        }

        &:hover{
            background-color: #404040;
            color: #ffffff;
        }
                    
        &.sel{
            border-bottom: 2px solid #202020;
        }
    }
}

.breadcrumb-section{

    display: block;
    margin: 5px 20px 10px 20px;
    padding: 0;
    //border: 1px dashed red;
    //border-bottom: 2px solid #cfcfcf;
    //text-align: center;

    &.reset-margin{
        margin: 5px 0 10px 0;
    }

    &.no-margin{
        margin: 0;
    }
       

    .crumb{
        text-decoration: none;
        color: #202020;
        user-select: none;
        display: inline-block;
        padding: 0; // 5px 15px 5px 15px;
        margin: 0 5px 0 0;
        border-bottom: 2px solid transparent;

        &::before{
            content: "/";
            margin-right: 5px;
        }

        &:not(.no-link){
            &:hover{
                color: $primary;
                
            }
        }
        
        &.no-link{
            font-weight: bold;
            color: #000000;
        }
        
        
    }

}

.code-font{
    font-family: 'Courier New', Courier, monospace;
    &.bold{
        font-weight: bold;
    }
}

.message{
    font-size: 9pt;
    color: #404040;
    padding: 20px;
    &.no-pad{
        padding: 0;
    }

    &.centered{
        position: absolute;
        left:0;
        top: 0;
        width:100%;
        height:100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    &.h-centered{
        width: 100%;
        text-align: center;
    }
}


.compute-cover{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: $z-index-compute-cover;

    >.message{

        background-color: #ffffff;
        padding: 10px;
        font-weight: 500;
        
    }
}

.hover1{
    &:hover{
        background-color: #1298df !important;
        color: #ffffff;
    }
}

.help{
    font-size: 9pt;
    
    p{
        font-size: unset;
        //border: 1px solid red;
    }

    .code-section{
        display: inline-block;
        font-family: monospace;
        border: 1px solid #cfcfcf;
        padding: 2px 4px;
        margin: 0 4px;
    }

    .code-block{
        
        font-family: monospace;
        background-color: #202020;
        padding: 10px;
        color: #cfcfcf;
        margin: 10px 0;
    }
}