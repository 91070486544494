@import "../../styles/colors.scss";

.top-dashboard{
    
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 
        'topbar'
        'main';

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    >.main{
        grid-area: main;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        overflow-x: hidden;
        overflow-y: auto;

        display: flex;
        flex-direction: column;
        align-items: center;

        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYlWP48uXL/+fPn2PFX758+c8AY2CTfP78OUQBuiJkNgO6LnTTiFeA1wq8jiTkTQB2Ne9x04gUuAAAAABJRU5ErkJggg==") repeat;

        .width-limiter{
            position: relative;
            width: 100%;
            max-width: 1300px;
            flex: 1 0 auto;
        

            display: grid;
            grid-template-rows: min-content 1fr;
            grid-template-areas: 'tabstrip' 'tabcontent';
            row-gap: 0;
            column-gap: 0;
            
            .tab-space{
                grid-area: tabstrip;
                padding-top: 10px;
                padding-left: 20px;
            }

            .tab-content{
                
                //border: 3px solid green;
                grid-area: tabcontent;
                // overflow-y: hidden;

                // position: absolute;
                // left: 0;
                // top: 0;
                // width: 100%;
                // height: 100%;
                // padding: 0 10px 10px 10px;

                // display: grid;
                // grid-template-columns: minmax(300px, 1fr) 2fr 300px;
                // grid-template-areas: "ru-section ra-section user-section";
                // column-gap: 10px;
                // row-gap: 10px;
                
                // @media screen and (max-width: 900px) {
                //     overflow-y: auto;

                //     grid-template-columns: 1fr 300px;
                //     grid-template-rows: min-content min-content;
                //     grid-template-areas: 
                //         "ra-section user-section"
                //         "ru-section user-section";
                // }

                // @media screen and (max-width: 650px) {
                //     overflow-y: auto;
                    
                //     grid-template-columns: 1fr;
                //     grid-template-rows: min-content min-content min-content;
                    
                //     grid-template-areas: 
                //         "ra-section"
                //         "ru-section"
                //         "user-section";
                // }

                // .section{
                //     background-color: #ffffff;
                //     padding: 20px;
                //     border: 1px solid #cfcfcf;

                //     overflow-y: auto;

                //     &.user{
                //         grid-area: user-section;
                //     }

                //     &.recently-used{
                //         grid-area: ru-section;
                //         //border: 1px solid orange;
                //     }

                //     &.recent-activity{
                //         grid-area: ra-section;
                //         //border: 1px solid green;
                //     }

                //     >.title{
                //         font-size: 10pt;
                //         //color: red;
                //     }
                // }
                
            }
        }

    }

    .recent-block{
        margin-bottom: 20px;

        .title{
            font-size: 10pt;
            margin-bottom: 10px;
            //border: 1px dashed teal;
            display: flex;
            align-items: center;
            

            .label{
                flex-grow: 1;    
            }

            .new{
                float: right;
            }
        }

        .search{
            input{
                font-size: 9pt;
                border: 1px solid #cfcfcf;
                border-radius: 10px;
                width: 100%;
                margin-bottom: 5px;
                font-family: 'Arial', 'Font Awesome 6 Pro';
                padding: 5px 10px;
            }
        }

        .item-list{
            font-size: 9pt;

            >.list-heading{
                font-size: 7pt;
                text-transform: uppercase;
                //border: 1px solid red;
                margin: 5px;
                &.margin-top{
                    margin-top: 10px;
                }
            }

            >.item{
                display: block;
                text-decoration: none;
                color: #404040;
                white-space: nowrap;
                text-overflow: hidden;
                overflow: hidden;

                user-select: none;
                
                padding: 5px 10px;
                &:not(.view-all){
                    border-bottom: 1px solid #cfcfcf;
                }

                &.loading{
                    border: 0;
                }

                &.view-all{
                    margin-top: 10px;
                    color: $primary;
                }

                &.sel{
                    background-color: #cfcfcf;
                }

                &:hover{
                    background-color: $primary;
                    color: $primary-fg;
                }
            }

            >.item2{
                text-decoration: none;
                color: #404040;
                
                display: grid;
                grid-template-columns: min-content min-content 1fr min-content;
                grid-template-areas: "icon ilabel plabel del";

                >.icon{ 
                    grid-area: icon; 
                    i{
                        &.admin{
                            color: $primary-med;
                        }
                    }
                }
                >.ilabel{ 
                    margin-left: 5px;
                    grid-area: ilabel;
                    white-space: nowrap;
                    text-overflow: clip;
                }
                >.plabel{ 
                    color: #a0a0a0;
                    padding-left: 10px;
                    grid-area: plabel;
                    text-align: right;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                >.del{
                    grid-area: del;
                    //border: 1px solid red;
                    //background-color: #ffffff;
                    color: #404040;
                    padding: 0px 7px;
                    margin-left: 10px;
                    &:hover{
                        background-color: #202020;
                        color: #efefef;
                    }
                }
                

                user-select: none;
                
                padding: 5px 10px;
                &:not(.view-all){
                    border-bottom: 1px solid #cfcfcf;
                }

                &.view-all{
                    margin-top: 10px;
                    color: $primary;
                }

                &:hover{
                    background-color: $primary;
                    color: $primary-fg;
                    >.plabel{ 
                        color: #ffffff;
                    }
                }
            }

        }
    }
}