.edit-etl-node{
    //border: 1px solid #cfcfcf;
    background-color: #ffffff;
    padding: 10px;
    width: 100%;
    height: 100%;
    font-size: 12px;

    display: grid;
    grid-template-columns: 1fr 60px;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'title trbuttons' 'content content';

    >.title{
        grid-area: title;
        //border-bottom: 1px solid #efefef;
        //padding: 0 0 10px 0;
        display: flex;
        align-items: center;
        //justify-content: center;
        
    }

    >.top-right-buttons{
        grid-area: trbuttons;
        //border: 1px solid red;
        text-align: right;
        //border-bottom: 1px solid #efefef;
    }

    >.content{
        grid-area: content;
        position: relative;
    }

    
}