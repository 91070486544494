.select-new-content-item{
    

    font-size: 9pt;
    border: 1px solid #cfcfcf;
    overflow-y: scroll;

    height: 100%;

    >.item-group{

        >.title{
            background-color: #efefef;
            font-size: 7pt;
            text-transform: uppercase;
            padding: 2px 10px;
            border-bottom: 3px solid #cfcfcf;
            border-top: 1px solid #cfcfcf;
        }

        >.items{
            display: flex;
            flex-wrap: wrap;
            flex-direction: row;
            padding: 5px;
            row-gap: 5px;
            column-gap: 5px;

            >.menu-item{
                //display: inline-block;
                user-select: none;
        
                font-size: 8pt;
                
                background-color: #ffffff;
                color: #202020;
                padding: 5px;
                border: 1px solid #efefef;
                width: 90px;
                height: 60px;

                display: inline-grid;
                grid-template-rows: 1fr min-content;

                .glyph{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    //border: 1px dashed #efefef;
                }

                .label{
                    text-align: center;
                    font-size: 7pt;
                }
        
                &:hover{
                    background-color: #64c8ff;
                    color: #000000;
                }
            }
        }
    }

    

}