@import "../styles/metrics.scss";

.project-layout{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;


    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 
        'topbar'
        'project-main'
        ;
    
    overflow: hidden;

    


    // see main.scss! (where? do you mean metrics.scss? or a jsx file?)
    .project-main{
        grid-area: project-main;
        position: relative;

        margin-left: $left-menu-width;
        margin-right: 0;
        margin-bottom: $bottom-bar-height;

        transition: 
            margin-left 0.75s ease-in-out,
            margin-right 0.75s ease-in-out;

        &.no-left-menu{
            margin-left: 0;
        }

        &.lm-closed{
            margin-left: 0;
        }

        &.right-menu-open{
            margin-right: $right-menu-width;
        }
    }

    @media screen and (max-width: $left-menu-collapse-point){
        .project-main{
            &.v1{
                margin-left: 0;
            }
        }
    }

    @media screen and (max-width: $left-menu-collapse-point2){
        .project-main{
            &.v2{
                margin-left: 0;
            }
        }
    }




}