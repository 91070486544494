.view-carousel{

    .flipper{
        text-align: center;
        

        .btn-dot{
            display: inline-block;
            color: #808080;
            padding: 4px;
            cursor: pointer;

            &:hover{
                color: #1298fd;
            }
        }
    }
}
