@import "../../../../styles/colors.scss";

.edit-agg-stage{

    

    .row-entry{
        display: grid;
        grid-template-columns: 60px 1fr;
        grid-template-areas: 'label container';
        column-gap: 10px;
        margin-bottom: 5px;
    }

    .c-label{
        grid-area: label;
        display: flex;
        align-items: center;
    }

    .container{
        grid-area: container;
        border: 1px solid #cfcfcf;
        padding: 3px;

        &.dragging-over{
            border: 1px solid $primary;
        }

        display: flex;
        flex-direction: row;
        gap: 5px;
        background-color: #fafafa;
    }


    
    .btn1{
        background-color: #ffffff;
        padding: 5px 10px;
        border: 1px solid #afafaf;
        user-select: none;
        position: relative;

        white-space: nowrap;        
        text-overflow: ellipsis;

        //text-align: center;
        &.ghost{
            border: 1px dashed #cfcfcf;
            background-color: transparent;
            color: #909090;
        }

        &.hide{
            opacity: 0;
        }

        .btn-delete{
            cursor: pointer;
            font-size: 8pt;
            display: none;
            //display: inline-flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            right: -5px;
            top: -5px;
            width: 20px;
            height: 20px;
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            border-radius: 10px;
            color: #202020;
            &:hover{
                background-color: #202020;
                color: #cfcfcf;
            }
            
        }

        &:hover{
            background-color: $primary;
            color: $primary-fg;
            .btn-delete{
                display: inline-flex;
            }
        }
        
    }

    .agg-btn{
        grid-area: agg;
    }

    .groups-btn{
        grid-area: groups;
    }

    .layout-section{
        grid-area: layout;
    }



}