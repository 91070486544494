@import "../../styles/colors.scss";

.string-explorer{
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 300px 1fr;
    grid-template-areas: 'heading-pane heading-pane' 'flow-section1 flow-section2';

    .loading-indicator{
        padding: 10px;
    }

    >.heading-pane { 
        grid-area: heading-pane;
        border-bottom: 1px solid #cfcfcf;
        //padding-bottom: 10px;
        padding: 0px 20px 10px;
        //border: 1px dashed red; 

    }

    >.select-vars { 
        //border: 1px dashed teal;
        grid-area: flow-section1;
        //padding: 10px 0; 
        overflow-x: hidden;
        overflow-y: scroll;
        padding: 0;

        border-right: 1px solid #cfcfcf;

        

    }

    >.view-content { 
        //border: 1px dashed green;
        grid-area: flow-section2;
        padding: 0; 
        overflow-y: scroll;
        font-size: 9pt;

        >.result-row{

            border-bottom: 1px solid rgba(0,100,200,0.1);
            padding: 10px 20px;

            ._id{
                display: block;
                margin-top: 5px;
                font-size: 7pt;
            }
        }
    }


    .field-item{
        font-size: 9pt;
        //white-space: nowrap;
        // text-overflow: clip;
        // overflow: clip;
        padding: 10px 20px;
        //cursor: pointer;
        user-select: none;

        border-bottom: 1px solid #cfcfcf;

        >.field-check{
            margin: 0 10px;
        }

        >.field-name{
            margin-right: 10px;
            font-weight: 500;
        }

        >.field-type{
            font-style: italic;
            margin-right: 10px;
            font-weight: 300;
            display: inline-flex;
            width: 16px;
            //border: 1px solid teal;
            align-items: center;
            justify-content: center;

            &.string{
                color: #990000;
            }

            &.numeric{
                color: #0064c8;
            }
            

        }

        >.field-label{
            color: teal;
        }

        &.sel{
            background-color: $primary;
            >.field-label{
                color: #ffffff;
            }
        }

        &:hover{
            background-color: #efefef;
            &.sel{
                background-color: darken($primary, 10);
            }
        }
    }

}