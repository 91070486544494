@import "../../styles/colors.scss";

.data-bind-button{
    border-radius: 20px;
    width: 26px;
    height: 26px;
    //margin-left: -5px;
    margin-right: 5px;
    padding: 0;
    //border: 1px dashed red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #9f9f9f;

    border: 1px solid $gray2;
    //background-color: #ffffff;

    &.sel{
        color: #202020;
        border: 1px solid $primary;
        background-color: #ffffff;
    }

    
    &:hover{
        background-color: rgba(255,255,255,0.5);
        border: 1px solid $primary;
    }
    
}