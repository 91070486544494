@import "../../styles/colors.scss";


$min-width: 600px;

.player-list-projects{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    
    
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'actions' 'list';

    >.actions{
        grid-area: actions;
        padding: 15px 20px;
        border-bottom: 1px solid #cfcfcf;
    }

    >.table-actions{
        display: grid;
        grid-template-columns: minmax(50px, 350px) 1fr min-content;

        .search{
            //border: 1px dashed teal;
        }

        .filter{
            //border: 1px dashed orangered;
            margin: 0 10px;

        }

        
    }

    >.list{
        grid-area: list;
        overflow-y: scroll;
        background-color: #ffffff;
        padding: 20px;
    }

    .projects-message{
        padding: 10px 0;

        &.error{
            color: red;
        }
    }


    .projects-list{

        width: 100%;

        .group-header{
            padding: 10px;
            border-bottom: 1px solid #cfcfcf;
            margin-bottom: 10px;
        }

        .group{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
            margin-bottom: 10px;

        }

        @media screen and (max-width: $min-width) {
            .group-header{
                margin-bottom: 0;
                background-color: #efefef;
            }
            .group{
                row-gap: 0;
                column-gap: 0;
                margin-bottom: 0;
            }
        }

        .project-block{
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            box-shadow: 0 2px 6px rgba(0,0,0,0.2);
            padding: 0px;
            position: relative;
            

            .full-label{
                display: block;
                padding: 20px;

                text-decoration: none;
            }

            .subscription-label{
                font-size: 8pt;
                color: $primary;
                text-decoration: none;
            }

            .project-label{
                font-size: 10pt;
                color: #202020;
            }
    
            .client-label{
                margin-top: 3px;
                font-size: 8pt;
                color: #202020;

                .date{
                    font-weight: 600;
                }

                .separator{
                    color: #cfcfcf;
                    margin: 0 5px;
                }

                .clabel{

                }
            }

            .project-modified{
                margin-top: 2px;
                font-size: 8pt;
                color: rgba(0,0,0,0.4);
            }
    
            .project-actions{
                margin-top: 10px;
            }

            .actions{
                position: absolute;
                top: 0;
                right: 0;
                //border: 1px dashed purple;

                //width: 70px;
                //padding: 5px;
                user-select: none;
                //display: flex;
                // align-items: center;
                // justify-content: center;
                //border: 1px solid purple;

                
            }

            .action-btn{
                display: inline-block;
                text-decoration: none;
                padding: 5px 12px;
                //background-color: $gray2;
                &:hover{
                    background-color: #000000;
                    color: $gray2;
                }
            }


            &:hover{
                background-color: #efefef;
            }

            

        }

        @media screen and (max-width: $min-width) {
            .project-block{
                box-shadow: none;
            }
        }

        


    }

    @media screen and (max-width: $min-width) {
        .list{
            padding: 0;
        }
    }
    
}