.product-edit{

    max-width: 100%; //300px;
    border: 1px solid #cfcfcf;
    display: inline-block;
    
    .product-label{
        text-align: center;
        padding: 10px;

        font-weight: 600;
        border-bottom: 1px solid #cfcfcf;
    }

    .att-box{
        padding: 5px 10px;
        zborder-bottom: 1px solid #cfcfcf;
        border: 1px dashed red;

        &.light{
            padding: 1px 10px;
            border-bottom: unset;
        }
    }
}