.content-value-input{


    display: grid;
    //grid-template-columns: min-content 1fr min-content;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: 'type' 'value' 'save';
    
    >.type{
        padding: 0px;
        margin-bottom: 5px;
    }

    >.value{
        padding: 0px;
        margin-bottom: 5px;
    }

    >.save{
        padding: 0px;
        margin-bottom: 5px;
        text-align: right;
    }
}

