@import "../../styles/colors.scss";

.edit-pipeline-stages-v2{

    //border: 5px dashed teal;
    
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'header' 'header2' 'content';

    .pipeline-header1{
        grid-area: header;
        padding: 0;
        //border: 1px solid #cfcfcf;
        
        padding: 5px 10px;
        background-color: #404040;
        color: #efefef;
    }

    .pipeline-header2{
        grid-area: header2;
        padding: 0;
        
        //border: 1px solid #cfcfcf;
        text-transform: uppercase;
        font-size: 8pt;
        font-weight: 600 ;
        border-bottom: 1px solid #cfcfcf;
        //padding: 5px 10px;
        color: #404040;

        display: flex;
        flex-direction: row;

        >.hlabel{
            //flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 5px 10px;
        }
        >.filename{
            text-transform: none;
            font-style: italic;
            font-weight: normal;
            flex-grow: 1;
            display: flex;
            align-items: center;
            padding: 5px 10px 5px 0;
            //white-space: nowrap;
            text-overflow: clip;
            overflow: hidden;
        }
        >.hbuttons{
            //border: 1px solid blue;
            display: flex;
            align-items: center;
            .hbtn{
                color: #606060;
                &.disabled{
                    color: #cfcfcf;
                }
                border: 0px solid #cfcfcf;
                background-color: #ffffff;
                margin-left: 5px;
                padding: 4px 7px;
                &:not(.disabled){
                    &:hover{
                        background-color: #202020;
                        color: #afafaf;
                    }
                }
            }
        }
    }


    .pipeline-content{
        grid-area: content;

        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        overflow-y: scroll;

        padding: 5px;

        &.dragging-over{
            border: 2px solid $primary;
        }

        .presets{
            border: 1px dashed #cfcfcf;
            text-align: center;
            background-color: #ffffff;
            padding: 10px;

            .note{
                //padding: 10px;
                margin-bottom: 10px;
            }
            .preset{
                color: #afafaf;
                user-select: none;
                display: inline-block;
                border: 1px dashed #cfcfcf;
                border-radius: 5px;
                padding: 5px 10px;
                text-decoration: none;

                i{
                    margin-left: 5px;
                }
                &:hover{
                    background-color: #202020;
                    color: #afafaf;
                    
                }
            }
        }

        .pipeline-add-stage{
            padding: 10px;
            //border: 1px solid #cfcfcf;
            text-align: center;
        }

    }

    
}