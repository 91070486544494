.edit-selection{
    display: grid;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'tabs' 'selheader' 'seltab';

    .selection-header{
        grid-area: tabs;
        //border: 1px dashed teal;
        padding-top: 10px; // remove me later probably
    }

    .selection-sel-header{
        grid-area: selheader;
        padding: 10px 15px;
        border-bottom: 1px solid #cfcfcf;
    }

    .selection-sel{
        grid-area: seltab;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}