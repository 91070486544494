
.view-unknown-widget{

    display: block;
    unicode-bidi: embed;
    font-family: monospace;
    white-space: pre;

    overflow: auto;
    
}