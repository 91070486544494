.centered-message{
    text-align: center;
    width: 100%;
    height: 100%;
    min-height: 50px;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}

.edit-item-wrapper{
    
    position: relative;
    transition: all 0.5s ease;

    &.inline-block{
        display: inline-block;
    }

    min-height: 10px;
    min-width: 10px;

    &.fill{
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }

    >.ctype{
        opacity: 0;
        font-size: 8pt;
        color: #000000;
        background-color: darkcyan;
        display: inline-block;
        padding: 5px 5px 5px 10px;
        
        position: absolute;
        top: 0;
        //left: 0;
        right: 0;
        //width: 100%;
        text-align: right;
    }

    >.outline{
        pointer-events: none;
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;// calc(100% + 8px);
        height: 100%; //calc(100% + 8px);
        border: 4px solid black;
        z-index: -1;
        //margin: -2px;
        //background-color: beige;;
    }

    &.boxed{
        >.outline{
            width: calc(100% + 0px);
            height: calc(100% + 0px);
            //margin: -2px;
        }
        //margin: 6px;
    }

    // &.page-style{
    //     >.ctype{
    //         position: absolute;
    //         left: 0px; //-13px;
    //         width: 100%;
    //         top: -5px;
    //         border-radius: 0;
    //         opacity: 1.0;
    //         //text-align: right;
            
    //     }
    //     margin: 0;
    //     padding: 35px 10px 10px 10px;
    //     border-width: 5px 5px 5px 5px;
    // }

    &.boxed{
        //padding: 35px 5px 5px 5px;
        padding: 25px 5px;
    }

    .open,&:hover,&.boxed{
        //border-width: 5px 5px 5px 5px;
        border-width: 2px;
        
        

        //margin: 0px;
        //padding: 35px 10px 10px 10px;
        // &.hover-pad-top{
        //     padding-top: 25px;
        // }

        // &.hover-margin-top{
        //     margin-top: 25px;
        // }
        
        
        >.ctype{
            opacity: 1.0;
            z-index: 12;
            //color: #ffffff;
        }
        >.outline{
            opacity: 1.0;
            z-index: 11;
        }
    }


    >.ctype{
        >.smallbtn{
            padding: 5px 8px;
            &:hover{
                background-color: rgba(0,0,0,0.2);
                //color: #000000;
            }
            &.sel{
                background-color: rgba(0,0,0, 0.4);
                color: #ffffff;
                
            }
        }
    }


    
}


.popup-context{
        border: 1px solid #cfcfcf;
        padding: 20px;
        width: 700px;
        height: 500px;
        overflow: auto;
    }