@import "../../styles/colors.scss";

.edit-stage-model{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    .help-link{
        
        display: inline-block;
        padding: 5px 15px 5px 15px;
        
        
        &:hover{
            background-color: #404040;
            color: #ffffff;
        }
    }

    .main-center{
        padding: 0 20px 20px 20px;

        .code-section1{
            padding: 5px;
            border: 2px solid $primary;
        }

        .code-container{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
            //padding: 5px;

            
        }
    }

    
}
