@import "../../styles/colors.scss";

.image-picker{
    font-size: 9pt;

    .search-section{
        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-areas: 'box btn';

        .tb_search{
            grid-area: box;
            border: 1px solid #cfcfcf;
            border-radius: 5px;
            width: 100%;
            padding: 3px 5px;
        }

        .cancel-btn{
            grid-area: btn;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 25px;
            height: 25px;
            margin-left: 10px;
            &:hover{
                background-color: #202020;
                color: $gray2;
            }
        }

        margin-bottom: 10px;
    }
 
    .image-list{
        border: 1px solid #cfcfcf;
        overflow-y: scroll;
        width: 100%;
        height: 500px;
        user-select: none;

        .image-entry{
            padding: 5px 10px;
            &:hover{
                background-color: $primary;
            }
        }
        
    }


}