$color1: #64c8ff;

.mstudio-tab-control-layout{
    //border: 5px solid black;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'tabs' 'content';

    >.tab-strip{
        user-select: none;

        grid-area: tabs;
        padding-bottom: -5px;

        display: flex;
        flex-direction: row;

        // &.centered{
        //     justify-content: center;
        // }
        // &.right-align{

        // }
        
        .tab{
            display: inline-block;
            border: 1px solid #cfcfcf;
            padding: 5px 10px;
            background-color: #dfdfdf;
            margin-left: -1px;
            color: #202020;

            &.sel{
                background-color: #ffffff;
                color: #303030;
                border-bottom: 1px solid #ffffff;
            }

            &.esel{
                border: 2px solid $color1;
                border-bottom: 0;
            }

            &:hover{
                background-color: #404040;
                color: #efefef;
            }
        }

        .leftover{
            //background-color: red;
            border-bottom: 1px solid #cfcfcf;
            flex-grow: 1;
        }

    }

    >.tab-content{
        grid-area: content;
        overflow: hidden;
    }
    
}