@import "../../../styles/colors.scss";

.main-menu{
    background-color: #ffffff;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-size: 9pt;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: 'top-strip' 'main-content' 'bottom-strip';


    .top-strip{
        grid-area: top-strip;
        height: 50px;
        text-align: center;
        padding: 5px 20px;
        border-bottom: 1px solid #cfcfcf;

        .menu-item{
            display: inline-block;
            text-decoration: none;
            color: #202020;
            padding: 10px 15px;

            &.sel{
                background-color: $gray2;
            }
            
            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }

    }

    .main-content{
        grid-area: main-content;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        background-color: #ffffff;

        >.centered-message{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    .bottom-strip{
        grid-area: bottom-strip;
        //background-color: #4db5ec;
        //color: rgba(0,0,0,0.8);

        background-color: #404040;
        color: #4db5ec;

        padding: 3px 10px;
        font-size: 7pt;
        text-transform: uppercase;
    }

}