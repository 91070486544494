.edit-config-atts{
    

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    overflow-y: auto;
}