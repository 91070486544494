.App {
  
    font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 10pt;

    // temporary hack so preview page will show up. fix me! TODO:
    >.edit-page{
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        overflow-x: hidden;
        overflow-y: auto;
        //border: 10px solid teal;
    }

}

html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}



.attbox{
    //border-bottom: 1px solid $gray2;
    margin-bottom: 5px;
    padding-bottom: 5px;
}


