@import "../../styles/colors.scss";

.top-dashboard-home{
                
    //border: 3px solid green;
    grid-area: tabcontent;
    overflow-y: hidden;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 0 10px 10px 10px;

    display: grid;
    grid-template-columns: minmax(300px, 1fr) 2fr 300px;
    grid-template-areas: "ru-section ra-section user-section";
    column-gap: 10px;
    row-gap: 10px;
    
    @media screen and (max-width: 900px) {
        overflow-y: auto;

        grid-template-columns: 1fr 300px;
        grid-template-rows: min-content min-content;
        grid-template-areas: 
            "ra-section user-section"
            "ru-section user-section";
    }

    @media screen and (max-width: 650px) {
        overflow-y: auto;
        
        grid-template-columns: 1fr;
        grid-template-rows: min-content min-content min-content;
        
        grid-template-areas: 
            "ra-section"
            "ru-section"
            "user-section";
    }

    .section{
        background-color: #ffffff;
        padding: 20px;
        border: 1px solid #cfcfcf;

        overflow-y: auto;

        &.user{
            grid-area: user-section;
        }

        &.recently-used{
            grid-area: ru-section;
            //border: 1px solid orange;
        }

        &.recent-activity{
            grid-area: ra-section;
            //border: 1px solid green;
        }

        >.title{
            font-size: 10pt;
            //color: red;
        }
    }
    
}