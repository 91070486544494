@import "../../styles/colors.scss";

.settings-viewer{

    >.tree{
        width: 250px;
        border-right: 1px solid #cfcfcf;
        
        >.tree-item{
            user-select: none;
            
            padding: 5px 15px;
            border-bottom: 1px solid #cfcfcf;

            &.sel{
                background-color: darken($primary,5%);
                color: $primary-fg;
            }

            &:hover{
                background-color: $primary;
                color: $primary-fg;
            }
        }
    }

    >.editor{

        font-size: 9pt;
        padding: 10px 20px;
        //border: 1px solid red;
        overflow-y: scroll;

        >.category{

            border-bottom: 1px solid #404040;
            //padding-bottom: 10px;
            margin-bottom: 15px;

            >.title{
                font-size: 12pt;
                font-weight: 500;
                margin-bottom: 10px;
            }

            >.setting{
                padding-bottom: 10px;
                margin-bottom: 15px;
                &:not(:last-child){
                    border-bottom: 1px dashed #cfcfcf;
                }

                >.label{
                    font-weight: 500;
                }

                >.description{
                    font-weight: 300;
                    font-style: italic;
                    padding: 5px 0;
                }

                select{
                    margin: 5px 0;
                }

                label{
                    user-select: none;
                }

                .number-box{
                    display: inline-block;
                    user-select: none;
                    border: 1px solid #cfcfcf;
                    padding: 5px 10px;
                    &:hover{
                        background-color: $primary;
                        color: $primary-fg;
                    }

                }
            }
        }

    }

}