@import "../../styles/colors.scss";

.edit-pipeline{

    position: absolute;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'sel-ds' 'sel-rev' 'edit-stages';

    >.select-dataset{
        grid-area: sel-ds;

        padding: 5px 10px;
        border-bottom: 1px solid #cfcfcf;

        //position: absolute;
    }

    >.select-revision{
        grid-area: sel-rev;

        padding: 5px 10px;
        border-bottom: 1px solid #cfcfcf;
    }

    >.edit-pstages{
        grid-area: edit-stages;

        position: absolute;
        width: 100%;
        height: 100%;
    }


}