@import "../../styles/colors.scss";

.select-user-win{
    border: 1px solid #cfcfcf;
    padding: 10px;
    background-color: #ffffff;   
    font-size: 9pt;
    min-height: 100px;

    .search{
        input{
            font-size: 9pt;
            border: 1px solid #cfcfcf;
            border-radius: 10px;
            width: 100%;
            margin-bottom: 5px;
            font-family: 'Arial', 'Font Awesome 6 Pro';
            padding: 5px 10px;
        }
    }

    .user-list{
        height: 200px;
        overflow-y: scroll;

        .user-item{
            font-size: 9pt;
            user-select: none;
            padding: 5px;
            border-bottom: 1px solid #cfcfcf;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;

            &:hover{
                background-color: $primary;
                color: $primary-fg;
            }

            i{
                display: inline-block;
                margin-right: 7px;
            }

            .email{
                display: inline-block;
                color: #afafaf;
                margin-left: 7px;
            }
        }
    }
}