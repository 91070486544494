.resource-list{
    border: 1px solid #cfcfcf;
    

    >.entry{
        //border: 2px solid red;
        position: relative;
        padding: 5px 10px 5px 5px;
        user-select: none;

        display: grid;
        grid-template-columns: min-content min-content 1fr 0px;
        grid-template-areas: 'icon name label del';

        background-color: #f5f5f5;

        >.icon{
            grid-area: icon;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 30px;
        }

        >.name{
            grid-area: name;
            font-weight: 500;
            margin-right: 10px;
            white-space: nowrap;
            text-overflow: clip;
        }

        >.rlabel{
            grid-area: label;
            white-space: nowrap;
            text-overflow: clip;
            overflow: hidden;
            font-size: 8.5pt;
        }

        >.btn{
        }

        &:not(:last-child){
            border-bottom: 1px solid #cfcfcf;
        }

        >.btn{
            &.del{
                visibility: hidden;
            }
        }

        &:hover{
            background-color: #ffffff;
            >.btn{
                &.del{
                    visibility: visible;
                }
            }
        }

        
    }
}