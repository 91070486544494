
.__edit-border{
    &.allow-hover{
        &:hover{
            border: 1px dashed #990000;
        }
    }
}

.__selection-border{
    border: 2px solid #64c8ff;
    color: #202020;
    font-size: 9pt;
    .buttons{
        position: absolute;
        margin-left: 30px;
        //top: 3px;
        //right: 3px;
        background-color: #64c8ff;
        color: #202020;
        text-align: right;
        .btn{
            display: inline-block;
            padding: 3px 5px;
            user-select: none;
            &:hover{
                
                background-color: #202020;
                color: #efefef;
            }
        }
    }
}

.arbitrary-content{
    user-select: none;
    background-color: lightblue;
    color: #404040;
    font-style: italic;
    padding: 15px;
    font-size: 9pt;

    p{
        font-size: 9pt;
        font-weight: 300;
    }

    &.v2{
        background-color: #202020;
        color: #afafaf;
    }

    &.v3{
        background-color: #ffffff;
    }

    .btn-edit{
        display: inline-block;
        border: 1px solid red;
    }
    
}

.arbitrary-menu-item{
    user-select: none;
    width: 250px;
    padding: 5px 10px;
    background-color: #ffffff;
    color: #404040;
    border-bottom: 1px solid #cfcfcf;

    &:hover{
        background-color: #64c8ff;
        color: #404040;
    }
}

.arbitrary-menu{
    font-size: 9pt;
    min-width: 250px;
    background-color: #ffffff;
    overflow-y: scroll;

    .item{
        user-select: none;
        padding: 5px 10px;
        background-color: #ffffff;
        color: #404040;
        border-bottom: 1px solid #cfcfcf;

        &:hover{
            background-color: #64c8ff;
            color: #404040;
        }
    }
}

.arbitrary-button{
    user-select: none;
    padding: 5px 10px;
    background-color: #efefef;
    color: 1px solid #cfcfcf;

    &:hover{
        background-color: #64c8ff;
        color: #404040;
    }

}