@import "../../styles/colors.scss";


.edit-att-ui{
    border: 1px solid #cfcfcf;
    padding: 20px;
    //background-image: linear-gradient(to bottom right, #efefef, #ffffff);
    background-color: #efefef;
    box-shadow: 0 2px 6px rgba(0,0,0,0.2); 
    margin: 20px;

    >.att-name{
        font-size: 12pt;
        font-weight: 500;
        padding-bottom: 10px;
        border-bottom: 1px solid #808080;
        margin-bottom: 10px;

        >.att-name{
            color: #000000;
        }

        >.att-label{
            color: #202020;
        }
    }

    .att-type{
        text-transform: uppercase;
        font-size: 8pt;
        color: $primary;
        margin-bottom: 10px;
    }

    >.att-label{
        color: #202020;
        padding-bottom: 10px;
        border-bottom: 1px solid #808080;
        margin-bottom: 15px;
    }

    

    >.levels{

        input{
            
            //border: 1px solid #cfcfcf;
            border: 0;
            //border: 1px dashed #cfcfcf;
            //border: 0;
            background-color: #ffffff;
            width: 100%;
            
        }

        >.att-level{
            //border-bottom: 1px solid #cfcfcf;
            padding: 0 5px;
            //margin-bottom: 5px;
            margin-bottom: -1px;
            background-color: #ffffff;

            //border: 1px dashed red;
            //border-radius: 5px;
            border: 1px solid #cfcfcf;
            padding: 10px 10px;
            


            display: grid;
            grid-template-columns: 30px 80px 1fr 170px 30px;
            grid-template-areas: 'vis value label coeff actions';

            &.header{
                border: 0;
                background-color: unset;
                color: #0064c8;
                text-transform: uppercase;
                font-size: 8pt;
            }
            
            >.grip{
                grid-area: grip;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            >.level-vis{
                grid-area: vis;
                //display: flex;
                //align-items: center;
                //justify-content: center;
                //border: 1px dashed red;
            }

            >.level-value{
                grid-area: value;
                
                //border: 1px solid #cfcfcf;
                //padding: 5px 10px;
                width: 80px;
                
            }
            >.level-label{
                grid-area: label;
                margin-left: 5px;
                
                
            }
            >.level-coeff{
                grid-area: coeff;
                margin-left: 5px;
                width: 160px;
                input{
                    width: 100px;
                    border: 1px dashed #cfcfcf;
                }
            }
            >.actions{
                grid-area: actions;
                // display: flex;
                // align-items: center;
                // justify-content: center;
                //border: 1px dashed red;
            }
        }

    }
}