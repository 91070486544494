@import "../../styles/colors.scss";


.configurator{
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'top' 'content';

    .configurator-top{
        grid-area: top;
    }

    .configurator-content{
        grid-area: content;
        overflow: auto;
    }



    .btn-layout{
        display: inline-block;
        background-color: #ffffff; // $gray2;
        padding: 5px 10px;
        user-select: none;
        margin-left: 5px;

        &.no-left-margin{
            margin-left: 0;
        }

        &.disabled{
            opacity: 0.8;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
        }

        &:hover{
            background-color: #404040;
            color: #ffffff;
        }

        .subtle{
            opacity: 0.5;
            font-size: 8pt;
            margin-left: 5px;
        }
    }

    .action-bar{
        background-color: #f4f4f4;
        padding: 5px;
        //margin-bottom: 5px;
    }


    .view-config-as-rows{
        
        //position: relative;


        //width: 100%;
        //overflow: auto;


        table{
            table-layout: fixed;
            row-gap: 0;
            column-gap: 0;
            border-spacing: 0;
        }

        thead{
            position: sticky;
            top: 0px;

            .height-transitioner{
                height: 100px;
                max-height: 28px;

                transition: max-height 0.5s ease;
                

                &.taller{
                    max-height: 56px;
                }

                
            }

            th{
                text-align: left;
                
                
                //padding-left: 20px;
                
                //border: 1px dashed red;
                //border-bottom: 1px solid #cfcfcf;
                white-space: nowrap;
                text-overflow: clip;
                background-color: $gray2;

                &.border-right{
                    border-right: 1px solid #cfcfcf;
                }

                &.cat-label{
                    padding: 5px 20px;
                }

                .col-label{
                    padding: 5px 20px;
                    //border: 1px dashed red;
                    height: 28px;

                    &.extra-right-pad{
                        padding-right: 40px;
                    }
                }

                ._relative{
                    position: relative;
                }

                
                .btn-column-menu-space{
                    position: absolute;
                    display: inline-flex;

                    //border: 1px solid red;
                    right: 2px;
                    top: 0;
                    height: 100%;

                    align-items: center;
                    

                    .btn-column-menu{
                        padding: 3px 8px;
                        //height: 20px;
                        //border: 1px solid blue;

                        &:hover{
                            background-color: #202020;
                            color: #efefef;
                        }
                    }

                }

                
        
                .col-actions{
                    font-weight: normal;
                    padding: 5px 0;
                    height: 28px;

                    //border: 1px dashed blue;

                    visibility: hidden;
                    transition: visibility 0.5s linear;
                        
                    transition-delay: 0s;
                    transition-duration: 0s;
        
                    &.visible{
                        visibility: visible;
                        
                        transition-delay: 0.5s;
                        transition-duration: 0.5s;
                    }
                }

                .hover-sort{
                    user-select: none;
                    &:hover{
                        background-color: $primary;
                        color: $primary-fg;
                    }
                }

                .btn-reset-sel-products{
                    border: 0;
                    background-color: transparent;
                    color: #606060;
                    margin: 0;
                    padding: 2px 3px;
                    //border: 1px dashed red;
                    &:hover{
                        color: $primary;
                    }
                }

            }

            
            // tr.multi-selection-header{
            //     th{
            //         background-color: $primary;
            //     }
            // }

            tr{
                &:last-child{
                    //
                    th{
                        border-bottom: 1px solid #cfcfcf;
                    }
                }
            }
        }

        .label{
            min-width: 200px;
            //background-color:$gray2;
            &.white {
                //background-color: #ffffff;
            }
            white-space: nowrap;
            text-overflow: clip;
            padding-left: 20px;
            padding-right: 20px;
            
            &.sticky-top{
                position: sticky;
                top: 0;  
                z-index: 10; 
            }
            // &.sticky-left{
            //     position: sticky;
            //     left: 0;   
            //     z-index: 9;
            // }

        }


        tbody{
            tr{
                &.sel{
                    td{
                        background-color: $primary;
                        &.label{
                            .sel-btn{
                                color: #ffffff;
                            }
                        }
                    }
                }

                td{
                    &.label{  
                        .sel-btn{
                            margin-left: -15px;
                            //border: 1px solid red;
                            margin-right: 8px;
                            color: rgba(0,0,0,0.1);
                            &:hover{
                                color: rgba(0,0,0,0.9);
                            }
                        }
                    }

                    .availability-toggle{
                        display: inline-block;
                        color: $primary;
                        font-size: 11pt;
                        .not-available{
                            color: rgba(0,0,0,0.5)
                        }
                    }
                }

                &.sel{
                    td{
                        .availability-toggle{
                            color: #000000;
                        }
                    }
                }
            }
        }


        .cell{
            //padding: 5px 5px;
            //border: 1px dashed red;
            &.row-style{
                padding: 0;
            }
            &.centered{
                text-align: center;
            }
            &.has-value{
                text-align: right;
                padding-right: 20px;
            }
            &.stale{
                opacity: 0.5
            }
            &.border-right{
                border-right: 1px solid #cfcfcf;
            }
        }

        th.cell{
            //border-bottom: 1px solid #cfcfcf;
            //padding: 10px 5px;
        }

        td.cell{
            border-bottom: 1px solid #cfcfcf;
        }

        .att-input{
            min-width: 150px;
        }

        tr{
            &.not-available{
                .cell{
                    background-color: #efefef;
                    opacity: 0.5;
                }
                .att-input, select, input, .contin-box{
                    background-color: #efefef;
                }
            }
        }

        tr{
            &.agg{
                .cell{
                    //padding: 5px 10px;
                    // background-color: $primary;
                    // color: $primary-fg;

                    background-color: #efefef;
                    border-bottom: 2px solid $primary;
                }
            }
        }
        
    }


    .view-config-as-tiles{
        //padding: 20px;
        position: relative;
        width: 100%;
        //border: 1px solid #cfcfcf;
        overflow: auto;
        

        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
        row-gap: 20px;
        column-gap: 10px;

        .tile{
            border: 1px solid #cfcfcf;
            //border-radius: 10px;
            background-image: linear-gradient(#ffffff, $gray2);
            padding-bottom: 15px;
            
            
        }

        .product-label{
            user-select: none;
            //color: teal;
            font-weight: 500;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.2em;
            
            //padding: 5px 10px;

            label{
                display: flex;
                //border: 1px solid red;
                align-items: center;
                justify-content: center;
                padding: 5px 10px;
            }

            // &.sticky-top{ // not working
            //     position: "sticky";
            //     top: 100px;
                
            // }
        }

        .cell{

            // &:hover{
            //     background-color: $primary;
            // }
            
            &:not(:last-child){
                border-bottom: 1px solid #cfcfcf;
            }
        }


    }


    .edit-contin-column{
        //border: 1px dashed blue;
        display: flex;
        align-items: center;
        
        span{
            display: inline-block;
        }

        .tb{
            flex-grow: 1;
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            margin-right: 3px;
            margin-left: 3px;

            display: flex;
            input{
                flex-grow: 1;
                text-align: center;
            }

            .adjuster{
                display: flex;
                flex-direction: column;
                border-left: 1px solid #cfcfcf;
                .btn-adjust{
                    flex-grow: 1;
                    //border: 1px solid red;
                    margin: 0;
                    padding: 0 6px;
                    font-size: 4pt;
                    &:first-child{
                        border-bottom: 1px solid #cfcfcf;
                    }
                    &:hover{
                        background-color: #404040;
                        color: #efefef;
                    }
                }
            }

        }

        .btn-adjustment-type{
            border: 0;
            //border: 1px dashed orange;
            border-right: 1px solid #cfcfcf;
            background-color: transparent;
            width: 25px;
            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }

        .btn-reset{
            border: 0;
            //border: 1px dashed orange;
            border-left: 1px solid #cfcfcf;
            background-color: transparent;
            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }

        .btn-adjust{
            border: 0;
            display: flex;
            align-items: center;
            background-color: transparent;
            color: #afafaf;
            padding: 2px 1px;
            //border: 1px dashed red;
            margin: 0;
            &:hover{
                color: #202020;
            }
        }
    }

}