@import "../../styles/colors.scss";

.content-adder-button{
    padding: 4px;
    user-select: none;

    

    &.mini{
        padding: 0;
    }

    .internal{
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        width: 100%;
        height: 100%;

        padding: 5px;
        border: 1px dashed #cfcfcf;
        font-size: 9pt;

        .label{
            margin-left: 5px;
            &.rot{
                transform: rotate(-90deg);
            }
        }

        .description{
            margin-top: 5px;
            font-weight: 300;
        }
    }

    &:hover{
        
        background-color: rgba(0,0,0,0.1);
        .internal{
            border: 1px solid $primary;
        }
    }

}