@import "../../styles/colors.scss";

.new-data-source{

    .connectors-scroller{
        border: 1px solid #cfcfcf;
        padding: 10px;
        width: 100%;
        max-height: 200px;
        overflow-y: scroll;

        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: auto;
    }

    .connector{
        display: inline-flex;
        align-items: center;
        height: 100px;
        font-size: 9pt;
        border: 1px solid #cfcfcf;
        margin: 0 10px 10px 0;
        padding: 10px;
        user-select: none;

        &.sel{
            background-color: $primary;
            color: $primary-fg;
        }
    }

    textarea{
        &.paste{
            height: 200px;
            resize: none;
        }
    }
}