@import "../../styles/zIndex.scss";
@import "../../styles/colors.scss";
@import "../../styles/metrics.scss";

.left-menu{
    // background-color: $gray2;
    // border-right: 1px solid #cfcfcf;
    //overflow: hidden;

    grid-area: project-main;
    position: absolute;

    top: 0;
    height: 100%;
    //padding-bottom: 300px; //$bottom-bar-height;
    width: $left-menu-width;

    transition: left 0.5s ease-in-out;

    // default
    left: 0;
    // // default collapsed
    // @media screen and (max-width: $left-menu-collapse-point){
    //     left: 0 - $left-menu-width + 50px;
    // }

    &.collapsed{
        left: 0 - $left-menu-width;
    }

    &.open{
        left: 0;
    }

    // these values are pre-rotation
    $tab-height: 30px;
    $tab-width: 50px;

    .openment-tab{

        opacity: 0.5;
        position: absolute;
        left: $left-menu-width - 1px;
        z-index: $z-index-left-menu + 1;
        top: 5px - $tab-height;
        width: $tab-width;
        height: $tab-height;
        border: 1px solid #cfcfcf;
        border-bottom: 0px;
        background-color: $gray2;
        border-radius: 5px 5px 0 0;
        transform-origin: 0px $tab-height;
        transform: rotate(90deg);

        display: flex;
        align-items: center;
        justify-content: center;;

        &:hover{
            background-color: darken($gray2, 5%);
        }
        

    }

    &.open{
        .openment-tab{
            left: $left-menu-width - $tab-height - 1;
        }
    }


    &.pages{
        //width: 450px;
        //width: 400px;
    }

    z-index: $z-index-left-menu;

    // // note: this should move to a right menu i think
    // &.overlay{

    //     z-index: $z-index-left-menu-overlay;
    //     background-color: $gray2;
    //     position: relative;
    //     transition: left 0.5s ease;

    //     &.closed{
    //         left: -100%;
    //     }
    //     &.open{
    //         left: 0;
    //     }
    // }


    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr $bottom-bar-height;
    grid-template-areas: 'lmc' '.';

    .left-menu-content{
        grid-area: lmc;
        overflow-y: auto;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;

        background-color: $gray2;
        border-right: 1px solid #cfcfcf;
        

        display: grid;
        grid-template-rows: min-content min-content 1fr;
        
        .title{
            font-size: 10pt;
            text-transform: uppercase;
            text-align: center;
            padding: 10px 20px;
            position: relative;
            text-decoration: none;

            .back-button{
                
                position: absolute;
                left: 20px;
                top: 5px;
                width: 25px;
                height: 25px;
                //background-color: #afafaf;

                display: inline-flex;
                align-items: center;
                justify-content: center;

                text-decoration: none;
                color: unset;
            }

            .right{
                
                position: absolute;
                right: 20px;
                top: 5px;
                width: 25px;
                height: 25px;
            }

            &.show-hover{
                &:hover{
                    background-color: $primary;
                    color: #ffffff;
                }
            }
        }

        .scroller{
            overflow-x: hidden;
            overflow-y: auto;
            padding: 0px 20px 10px 20px;

            &.vsplit{
                overflow: hidden;
                padding: 0;

                display: grid;
                grid-template-rows: 1fr 1fr;

                >.top-section{
                    overflow-x: hidden;
                    overflow-y: auto;
                    padding: 0px 20px 10px 20px;
                    //border: 1px dashed red;
                }
                >.bottom-section{
                    border-top: 1px solid #cfcfcf;
                    //border: 1px dashed orange;
                    overflow-x: hidden;
                    overflow-y: auto;
                }


            }
        }

        .summary{
            //border: 1px solid red;
            p{
                padding: 0 0 10px 0;
                margin: 0;
            }
        }

        .action-bar-sticky{
            position: sticky;
            top: 0;
            
            background-color: $gray2;
            padding-bottom: 10px;

            .float-right{
                float:right;
            }
        }

        .menu-item{
            user-select: none;

            background-color: #ffffff;
            border-bottom: 1px solid $gray2;
            color: #606060;
            text-decoration: none;

            //padding: 10px 20px 10px 10px;
            font-size: 9pt;

            display: grid;
            grid-template-columns: min-content 1fr min-content min-content min-content;
            grid-template-areas: 'grip label btn1 btn2 menu';
            
            .grip{
                grid-area: grip;
                display: flex;
                align-items: center;
                justify-content: center;
                //border: 1px solid red;
                width: 30px;
                &.can-expand{
                    &:hover{
                        //background-color: #404040;
                        color:$gray2;
                        font-weight: bold;
                    }
                }
            }

            .label{
                //border: 1px solid blue;
                grid-area: label;
                padding: 10px 5px 10px 0;
                text-decoration: none;
                color: unset;

                overflow: hidden;
                text-overflow: clip;

                .instance-selector{
                    display: inline-block;
                    padding: 5px 10px;

                    //width: 100%;
                    margin-bottom: 3px;

                    
                    
                    
                    border: 1px solid #a0a0a0;
                    background-color: #ffffff;
                    color: #505050;
                    &:hover{
                        background-color: #ffffff;
                        color: #505050;
                    }

                    
                }


                &.page{
                    display: grid;
                    grid-template-rows: 1fr min-content;
                    grid-template-areas: 'label' 'url';

                    .page-label{
                        display: block;
                        grid-area: label;
                    }

                    .page-url{
                        display: block;
                        grid-area: url;
                        color: #afafaf;
                        //float: right;
                        font-style: italic;
                        font-size: 8pt;
                    }
                }

                

                
            }

            .btn1{ grid-area: btn1; }
            .btn2{ grid-area: btn2; }
            .menu{ grid-area: menu; }
            .btn1, .btn2, .menu{
                display: flex;
                align-items: center;
                justify-content: center;
                width: 30px;
                text-decoration: none;
                color: #404040;
                &:hover{
                    background-color: $primary;
                    color: #ffffff;
                }
            }

            // .menu{
            //     grid-area: menu;
            //     display: flex;
            //     align-items: center;
            //     justify-content: center;
            //     //border: 1px solid purple;
            //     width: 30px;
            //     //opacity: 0.0;
            // }

            &:hover{
                // .menu{
                //     opacity: 1.0;
                //     //background-color: #404040;
                // }
                .menu, .btn1, .btn2{
                    color: $gray2;
                }
            }


            &:not(.hide-hover){
                &:hover{
                    background-color: lighten($secondary, 20%);
                    color: $secondary-fg;
                    .glyph{
                        color: #ffffff;
                    }
                }
            }

            &.sel{
                background-color: $secondary;
                color: $secondary-fg;

                .menu{
                    color: $secondary-fg;
                }
                
                &:hover{
                    //background-color: darken($secondary, 20%);
                    background-color: $secondary;
                    color: $secondary-fg;
                }

                .label{
                    &.page{
                        .page-url{
                            color: #000000;
                        }
                    }
                }
            }

            
        }

        .menu-item-stub{
            user-select: none;

            display: flex;
            align-items: center;
            
            &.center{
                justify-content: center;
            }
            
            color: #606060;
            padding: 10px 20px 10px 10px;
            font-size: 9pt;
            
        }

        // moved to Basic.scss
        // .tab-section{
        //     display: block;
        //     margin: 5px 20px 10px 20px;
        //     padding: 0;
        //     border-bottom: 2px solid #cfcfcf;
        //     //text-align: center;

        //     .tab{
        //         user-select: none;
        //         display: inline-block;
        //         padding: 5px 15px 5px 15px;
        //         margin: 0 0 -2px 0;
        //         border-bottom: 2px solid transparent;

        //         &:hover{
        //             background-color: #404040;
        //             color: #ffffff;
        //         }
                            
        //         &.sel{
        //             border-bottom: 2px solid #202020;
        //         }
        //     }
        // }

        

        .textbox1{
            border: 1px solid #cfcfcf;
            padding: 5px 10px;

            &.fullwidth{
                width: 100%;
            }
        }



        .form-row{
            padding: 10px 0px;
            //border-bottom: 1px solid #cfcfcf;
            font-size: 10pt;

            .property{
                display: block;
                margin-bottom: 5px;
            }

            .property-value{
                display: block;

                .textbox1{
                    border: 1px solid #cfcfcf;
                    padding: 5px 10px;

                    &.fullwidth{
                        width: 100%;
                    }
                }
            }
        }

    }

}