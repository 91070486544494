@import "../../styles/colors.scss";

.my-login-stats{

    >.title{
        font-size: 9pt;
    }

    >.box{
        //border: 1px solid #cfcfcf;
        background-color: #ffffff;

        display: flex;
        flex-direction: row;
        justify-content: center;
        padding: 5px;

        .week-column{
            display: inline-block;
            //background-color: lightblue;// #dfdfdf;
            margin-right: 2px;
            padding: 1px;
            //width: 10px;
            //height: 100px;

            display: flex;
            flex-direction: column;
        }

        .day-label{
            display: inline-block;
            //background-color: rgba(0,0,0,0.1);
            margin: 0 0 2px 0;
            width: 10px;
            height: 10px;
            font-size: 6pt;
            text-align: right;
        }

        .day-box{
            display: inline-block;
            background-color: rgba(0,0,0,0.1);
            margin: 0 0 2px 0;
            width: 10px;
            height: 10px;

            &.weekend{
                background-color: rgba(0,0,0,0.05);
            }

            &.future{
                background-color: transparent;
            }

            &.hit{
                background-color: $primary;;
            }

            &:hover{
                border: 1px solid #404040;
            }
        }

    }
}