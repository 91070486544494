@import "../../styles/colors.scss";

.add-child-content{
    
    
}

.add-child-content-win{
    border: 1px solid #cfcfcf;
    //background-color: #ffffff;
    font-size: 9pt;

    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr 0;//200px 300px;
    grid-template-areas: 'title title' 'left right';

    >.title{
        grid-area: title;
        padding: 5px 10px;
        border-bottom: 1px solid #cfcfcf;
        background-color: $gray2;
        color: #202020;
    }

    >.left{
        grid-area: left;
        overflow-y: scroll;
    }

    >.right{
        grid-area: right;
        
    }

    .category-menu{

        .category-label{
            padding: 5px 10px;
            background-color: $gray2;
            border-bottom: 2px solid $primary;
        }

    }

    .menu{
        display: flex;
        flex-wrap: wrap;
        
        padding: 10px;
        column-gap: 10px;
        row-gap: 10px;
        
        user-select: none;

        .menu-item{
            padding: 5px 10px;
            border: 1px solid #cfcfcf;
            width: 110px;
            height: 70px;
            
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .label{
                display: block;
                text-align: center;
            }

            .icon{
                display: block;
                text-align: center;
                font-size: 14pt;
                margin-top: 5px;
            }

            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }
    }
}