@import "../../styles/colors.scss";

.edit-pipelines{

    position: absolute;
    height: 100%;
    width: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: "select" "selected";

    .select-index{
        grid-area: select;
        

        .mini-tab{
            position: relative;
            
            user-select: none;
            cursor: pointer;
            padding: 4px 15px;
            border-radius: 5px 10px 0 0;
            display: inline-block;  
            
            border: 1px solid #cfcfcf;
            margin-right: -1px;
            background-color: #efefef;
            color: #808080;
            
            &.sel{
                background-color: #ffffff;//lighten($secondary, 20);
                font-weight: bold;
                color: #202020;
                border-bottom: 0;
            }

            &.plus{
                background-color: #ffffff;
                border: 1px solid #cfcfcf;
                //border-bottom: 0;
            }

        }

    }

    .selected-pipeline{
        grid-area: selected;

        position: absolute;
        height: 100%;
        width: 100%;

        //border: 1px dashed orchid;

    }

}