.edit-data-source{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    font-size: 12px;

    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'actions' 'tab-section' 'tab-content';

    >.actions{
        >.title{
            font-size: 12pt;
            i{
                font-size: 9pt;
                color: #afafaf;
            }
        }

        grid-area: actions;

        padding: 10px 20px;
    }

    >.tab-section{

    }

    >.tab-content{
        position: relative;
        overflow-x: hidden;
        overflow-y: hidden;

        padding: 0 20px 20px 20px;
    }

    

}