@import "../../../../styles/colors.scss";

.edit-select-stage{

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: 'top' 'entries' 'bot';

    >.top{
        grid-area: top;
    }
    >.entries{
        grid-area: entries;
        border: 1px solid #cfcfcf;
        overflow-y: scroll;
        .entry{
            padding: 5px 10px;
            border-bottom: 1px solid #efefef;
            &:hover{
                background-color: #efefef;
                
            }
        }
    }
    >.bot{
        grid-area: bot;
    }
}

.select-fields-window{
    font-size: 9pt;
    border: 1px solid #cfcfcf;
    padding: 10px;
    height: 100%;

    >.top{
        font-weight: 500;
        padding: 10px;
        //border-bottom: 1px solid #cfcfcf;
    }
    
    >.bottom{
        border-top: 1px solid #cfcfcf;
        padding: 10px;
        text-align: center;

        button{
            margin-right: 5px;
            &:last-child{
                margin-right: 0;
            }
        }
    }

    
    //height: 600px;
    
}