@import "../../styles/colors.scss";

.project-content{
    font-size: 9pt;
    //border: 10px solid black;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    

    .message-bar{
        z-index: 9999; // temp hack to overrule recharts
        font-size: 9pt;
        position: absolute;
        left: 0;
        width: 100%;
        top: calc(50% - 30px);
        height: 60px;
        //background-color: rgba(18,152,223,0.9);
        background-color: lighten($primary, 20%);
        color: darken($primary, 40%);
        .alt-color{
            color: #ffffff;
            //font-weight: 500;
            //text-transform: uppercase;
        }

        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;

        button{
            font-size: 9pt;
            padding: 2px 8px;
            margin-right: 5px;

        }
    }
}