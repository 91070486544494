@import "../../styles/colors.scss";

.code-editor{

    .code-start{
        margin-bottom: 10px;
    }

    .code-end{
        margin-top: 10px;
    }

    .code-start, .code-end{
        font-family: 'Courier New', Courier, monospace;
        font-size: 10pt;
        font-weight: bold;
    }

    .editor{
        border: 2px solid $primary;
    }

    
}