.add-fields-etl-node-editor{

    position: relative;
    height: 100%;
    max-height: 100%;
    width: 100%;

    overflow: hidden;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-columns: 1fr;
    grid-template-areas: 
        'top'
        'center'
        'bot';

    >.top{
        grid-area: top; 
        //border: 4px solid orange;
    }

    >.center{
        height: 100%;
        grid-area: center; 
        overflow: hidden;
        position: relative;

        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-columns: 1fr;
        grid-template-areas: 'tabs' 'ui-editor';

        >.tab-section2{
            grid-area: tabs;
            margin-bottom: 2px;
        }

        >.ui-editor{
            grid-area: ui-editor;
            display: grid;
            grid-template-rows: min-content 1fr;
            grid-template-columns: 1fr;
            grid-template-areas: 'actions' 'list';
            position: absolute;
            width: 100%;
            height: 100%;
            overflow: hidden;
            border: 1px solid #cfcfcf;
            
    
            >.actions{
                grid-area: 'actions';
            }
            >.list{
                grid-area: 'list';
            }
        }
    }
    
    >.bottom{
        grid-area: bot; 
    }

    



    
}
