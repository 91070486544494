
.color-picker{

    .circular-swatch{
        cursor: pointer;
        display: inline-block;
        width: 26px;
        height: 26px;
        border: 1px solid #afafaf;
        border-radius: 14px;
        padding: 3px;

        .fill{
            display: inline-block;
            background-color: #202020;
            border-radius: 14px;
            border: 1px solid #afafaf;
            width: 100%;
            height: 100%;

            &.cancel {
                background: 
                    linear-gradient(to top left,
                        rgba(0,0,0,0) 0%,
                        rgba(0,0,0,0) calc(50% - 1.0px),
                        rgba(0,0,0,0.4) 50%,
                        rgba(0,0,0,0) calc(50% + 1.0px),
                        rgba(0,0,0,0) 100%),
            }
        }
        
    }

}