@import "../../styles/colors.scss";

.flow-tools{
    
    padding: 20px;
    
    .widget-grid{
        display: grid;
        grid-template-columns: 1fr 1fr;
        //grid-template-rows: repeat(auto-fill, 60px);
        column-gap: 10px;
        row-gap: 10px;
        

        .widget-button{
            cursor: pointer;

            border: 1px solid #cfcfcf;
            padding: 5px 10px;
            height: 40px;
            background-color: #ffffff;
            display: flex;
            align-items: center;;
            font-size: 9pt;

            >.icon{
                color: teal;
                margin-right: 10px;
            }

            &:hover{
                background-color: $primary;
                color: $primary-fg;
                >.icon{
                    color: $primary-fg;
                }
            }
        }
    }
}