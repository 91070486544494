@import "../../styles/colors.scss";

.images-viewbox{
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'top' 'actions' 'main';

    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;


    .top-header{
        grid-area: top;
        //display: none;
        
        //text-align: right;
        font-size: 9pt;
        padding: 10px;
        border-bottom: 1px solid #cfcfcf;

        .left-section{
            
        }

        .right-section{
            float: right;
        }

        .view-button{
            user-select: none;
            display: inline-block;
            background-color: $gray2;
            padding: 5px 8px;
            margin-right: 5px;

            &.sel{
                background-color: $primary;
                color: #ffffff;
            }
        }
    }

    .actions-header{
        grid-area: actions;
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;
    }

    .list-content{
        grid-area: main;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        overflow-y: scroll;

        &.unknown{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9pt;
            color: #808080;
        }

        &.swatches{

            display: grid;
            padding: 10px;
            grid-template-columns: repeat(auto-fill,190px);
            grid-template-rows: repeat(auto-fill,minmax(150px,150px));
            row-gap: 10px;
            column-gap: 10px;


            &.swatch-size-small{
                grid-template-columns: repeat(auto-fill,190px);
                grid-template-rows: repeat(auto-fill,minmax(150px,150px));
            }

            &.swatch-size-med{
                grid-template-columns: repeat(auto-fill,290px);
                grid-template-rows: repeat(auto-fill,minmax(250px,250px));
            }

            &.swatch-size-large{
                grid-template-columns: repeat(auto-fill,390px);
                grid-template-rows: repeat(auto-fill,minmax(350px,350px));
            }

            &.swatch-size-xl{
                grid-template-columns: repeat(auto-fill,590px);
                grid-template-rows: repeat(auto-fill,minmax(550px,550px));
            }

            &.swatch-size-xxl{
                grid-template-columns: repeat(auto-fill,1290px);
                grid-template-rows: repeat(auto-fill,minmax(850px,850px));
            }

        }

    }


}