@import "../../styles/colors.scss";

.view-semistructured-content{
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'heading-pane' 'code-section';

    .loading-indicator{
        padding: 10px;
    }

    >.heading-pane { 
        grid-area: heading-pane;
        border-bottom: 1px solid #cfcfcf;
        //padding-bottom: 10px;
        padding: 0px 20px 10px;
        //border: 1px dashed red; 

        display: flex;
        flex-direction: row;

    }

    >.code-section { 
        //border: 1px dashed teal;
        grid-area: code-section;
        //padding: 10px 0; 
        //overflow-y: scroll;
        padding: 0;
    }

    .select-revision{
        width: 300px;
    }


}