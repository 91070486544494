@import "../../styles/metrics.scss";

.player-layout{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;


    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 
        'topbar'
        'project-main'
        ;
    
    overflow: hidden;
    


    // see main.scss! (where? do you mean metrics.scss? or a jsx file?)
    .player-main{
        grid-area: project-main;
        position: relative;

        margin-left: 0;
        margin-right: 0;
        margin-bottom: 0;
    }


}