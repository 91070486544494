@import "../../styles/colors.scss";

.select-project-page{

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr min-content;
    grid-template-areas: 
        'topbar topbar'
        'main sidebar';

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .top-bar{
        grid-area: topbar;
    }

    .sidebar{
        grid-area: sidebar;
        width: 300px;
        border-left: 1px solid #cfcfcf;
        padding: 0;
        //text-align: center;
        
        overflow-y: auto;
    }

    >.main{
        grid-area: main;
        overflow: hidden;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-areas: 'tabstrip' 'tabcontent';
        row-gap: 0;
        column-gap: 0;
        
        .tab-space{
            grid-area: tabstrip;
            padding-top: 10px;
            padding-left: 20px;
        }

        .tab-content{
            //border: 3px solid green;
            grid-area: tabcontent;
            overflow-y: hidden;

            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

    }

    
}