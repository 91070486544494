
.view-records{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 1px solid #cfcfcf;
    padding: 0px;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'header' 'data';
    
    //border: 5px solid black;

    .header{
        grid-area: header;;
    }

    .data-content{
        position: relative;
        grid-area: data;
        margin: 0;

        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-areas: 'h' 'c';

        .dc-header{
            grid-area: h;
        }

        .dc-content{
            grid-area: c;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
                        
            overflow: auto;
        }

        
    }
}