.view-revision-info{
    position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'heading-pane' 'flow-section';

    >.heading-pane { 
        grid-area: heading-pane;
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: 10px;
        padding: 10px 20px;
        //border: 1px dashed red; 
    }

    >.flow-section { 
        grid-area: flow-section;
        overflow-y: scroll;
        padding: 10px 20px;
    }


}