@import "../../styles/colors.scss";

.select-subscription-page{

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr min-content;
    grid-template-areas: 
        'topbar topbar'
        'main sidebar';

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    

    .top-bar{
        grid-area: topbar;
    }

    .sidebar{
        grid-area: sidebar;
        width: 300px;
        border-left: 1px solid #cfcfcf;
        padding: 0;
        //text-align: center;
        
        overflow-y: auto;
    }

    >.main{
        grid-area: main;
        overflow: hidden;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-areas: 'tabstrip' 'tabcontent';
        row-gap: 0;
        column-gap: 0;
        
        .tab-space{
            grid-area: tabstrip;
            padding-top: 10px;
            padding-left: 20px;
        }

        .tab-content{
            //border: 3px solid green;
            grid-area: tabcontent;
            overflow-y: hidden;

            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }

    }

    
}





$min-width: 600px;

.list-subscriptions{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'actions' 'list';

    >.actions{
        grid-area: actions;
        padding: 15px 20px;
        border-bottom: 1px solid #cfcfcf;
    }

    >.table-actions{
        display: grid;
        grid-template-columns: minmax(50px, 350px) 1fr min-content;

        .search{
            //border: 1px dashed teal;
        }

        .filter{
            //border: 1px dashed orangered;
            margin: 0 10px;

        }

        
    }

    >.list{
        grid-area: list;
        overflow-y: scroll;
        //background-color: #ffffff;
        background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAICAYAAADED76LAAAANElEQVQYlWP48uXL/+fPn2PFX758+c8AY2CTfP78OUQBuiJkNgO6LnTTiFeA1wq8jiTkTQB2Ne9x04gUuAAAAABJRU5ErkJggg==") repeat;
        padding: 20px;

    }

    .subcriptions-message{
        padding: 10px 0;

        &.error{
            color: red;
        }
    }


    .subscriptions-list{

        width: 100%;

        .group-header{
            padding: 10px;
            border-bottom: 1px solid #cfcfcf;
            margin-bottom: 10px;
        }

        .group{
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
            row-gap: 10px;
            column-gap: 10px;
            margin-bottom: 10px;

        }

        @media screen and (max-width: $min-width) {
            .group-header{
                margin-bottom: 0;
                background-color: #efefef;
            }
            .group{
                row-gap: 0;
                column-gap: 0;
                margin-bottom: 0;
            }
        }

        .subscription-block{
            background-color: #ffffff;
            border: 1px solid #cfcfcf;
            box-shadow: 0 2px 6px rgba(0,0,0,0.2);
            padding: 0px;
            position: relative;

            .clickable-area{
                display: block;
                text-decoration: none;

                display: grid;
            grid-template-columns: 1fr min-content;
            grid-template-areas: "info logo";

            }

            .info-section{
                grid-area: info;
                padding: 20px;

                .id-label{
                    display: block;
                    text-transform: uppercase;
                    font-size: 7pt;
                    margin-bottom: 5px;
                    .l1{
                        padding: 1px 8px;
                        color: #303030;
                        background-color: #afafaf;
                        
                    }
                }
    
                
    
                .subscription-labelx{
                    font-size: 8pt;
                    color: $primary;
                    text-decoration: none;
                }
    
                .subscription-label{
                    display: inline-block;
                    
                    font-size: 10pt;
                    
                    margin-bottom: 7px;
                    
                    .l1{
                        padding: 5px 15px;
                        background-color: #505050;
                        color: #e9e9e9;
                    }
                }
        
                .permission-set{
                    font-size: 7pt;
                    color: #202020;
    
                    .label1{
                        display: block;
                        margin: 5px 0;
                    }
    
                    .editor-pill, .reader-pill{
                        display: inline-block;
                        border: 1px solid #cfcfcf;
                        border-radius: 4px;
                        padding: 2px 10px;
                        margin-right: 5px;
                        font-size: 7pt;
                    }
    
                }
    
            }

            .logo-section{
                grid-area: logo;
                border: 1px dashed #cfcfcf;

                display: flex;
                align-items: center;
                justify-content: center;
                width: 100px;
                font-size: 7pt;
                margin: 10px;
            }
    
            .project-actions{
                margin-top: 10px;
            }

            .actions{
                position: absolute;
                top: 0;
                right: 0;
                //border: 1px dashed purple;

                //width: 70px;
                //padding: 5px;
                user-select: none;
                //display: flex;
                // align-items: center;
                // justify-content: center;
                //border: 1px solid purple;

                
            }

            .action-btn{
                display: inline-block;
                text-decoration: none;
                padding: 5px 12px;
                //background-color: $gray2;
                &:hover{
                    background-color: #000000;
                    color: $gray2;
                }
            }


            &:hover{
                background-color: #efefef;
            }

            

        }

        @media screen and (max-width: $min-width) {
            .subscription-block{
                box-shadow: none;
            }
        }

        


    }

    @media screen and (max-width: $min-width) {
        .list{
            padding: 0;
        }
    }
    
}