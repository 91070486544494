.edit-etl{
    //position: absolute;
    left: 0;
    width: 100%;
    top: 0;
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'heading-pane' 'flow-section';

    >.heading-pane { 
        grid-area: heading-pane; 
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid #cfcfcf;
        padding-bottom: 10px;
    }
    
    >.flow-section { 
        grid-area: flow-section; 
        position: relative;
    }

    .node-info{

        //background-color: rgb(50, 130, 184);

        >.inline-type{
            display: inline-block;
            font-weight: 500;
            margin-right: 5px;
            background-color: teal;
            border-radius: 10px;
            padding: 0px 5px;
            color: #ffffff;

            &.icon{
                background-color: transparent;
                color: teal;
            }
        }

        >.type{
            font-weight: 500;
            
        }

        >.label{
            display: inline-block;
        }
    }

    .old-input-pin{
        display: inline-block;
        position: "absolute";
        width: 16px;
        height: 16px;
        background-color: #0078BE;
        color: #ffffff;
        border-radius: 0 5px 5px 0;
        color: "#ffffff";
        //font-size: 9;
        padding: 0 0 2px 4px;

        &:hover{
            background-color: orchid;
        }
    }

    

}