@import "../../styles/colors.scss";

.data-table-diff{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    overflow-x: auto;
    

    &.auto-height{
        position: static;
        height: unset;
        overflow-y: hidden;
    }

    &.no-select{
        user-select: none;
    }

    .col-cb-select{
        text-align: center;
    }

    table{
        border-spacing: 0;
        table-layout: fixed;
    }
    
    th{
        font-weight: normal;
        //background-color: $gray2;
        background-image: linear-gradient(#ffffff, #fafafa);
        padding: 5px 20px 5px 10px;
        //border-bottom: 1px solid #808080;
        border-bottom: 2px solid $primary;
        position: sticky;
        top: 0;
        text-align: left;
        vertical-align: bottom;

        &.cat{
            background-image: none;
            border-bottom: 0;
            //border-left: 2px solid $primary;
        }
    }

    tr{
        &.row-hover{
            &:hover{
                .cell{
                    background-color: $primary;
                    color: $primary-fg;
                }
            }
            &.sel{
                .cell{
                    background-color: $primary;
                    color: $primary-fg2;
                }
            }
        }
    }

    .cell{
        padding: 5px 20px 5px 10px;
        border-bottom: 1px solid $gray2;
        //text-align: right;

        white-space: nowrap;
        text-overflow: clip;

        &.val-obj{
            //border: 1px dashed #cfcfcf;
            border-right: 1px solid #efefef;
            text-align: center;

            .n-size{
                font-size: 7pt;
                float: right;
                &::before{
                    content: "n="
                }
                
                color: peru;
            }
        }

        &.label-obj{
            //border: 1px dashed violet;

            .markup{
                margin-left: 5px;
                font-size: 7pt;
                //color: violet;
                color: tan;
            }
        }

        &.flag-diff{
            border: 2px solid red;
            background-color: rgba(150,0,0,0.3);
        }

        &:hover{
            background-color: $primary;
            color: $primary-fg;
        }

        
    }
}