@import "../../../styles/colors.scss";

.edit-crsyntax-box2{

    display: flex;
    align-items: center;
    justify-content: center;
    border: 0;
    width: 100%;
    height: 100%;
    
    >.frame1{
        width: 1200px;
        max-width: 100%;
        height: 600px;
        max-height: 100%;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;

        &:not(.expanded){
            box-shadow: 0 2px 6px rgba(0,0,0,0.5);   
        }

        font-size: 9pt;

        &.expanded{
            width: 100%;
            height: 100%;
        }


        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-areas: "titlebar" "main";

        >.titlebar{
            grid-area: titlebar;

            >.buttons{
                white-space: nowrap;        
            }
            
        }

        >.main{
            grid-area: main;
            position: relative;

            display: grid;
            grid-template-rows: min-content 1fr;
            grid-template-columns: min-content 1fr min-content;
            grid-template-areas: 
                "left-opener top-opener right-opener"
                "left-opener all right-opener";

            .top-opener{
                grid-area: top-opener;
            }
            
            .left-opener{
                grid-area: left-opener;
                background-color: tan;
                cursor: pointer;
                writing-mode:vertical-rl;
                user-select: none;

                &:hover{
                    background-color: yellowgreen;
                }
            }

            .all{
                grid-area: all;
                overflow: hidden;
                //display: none;

                position: absolute;
                width: 100%;
                height: 100%;

                .compute-running{
                    background-color: $primary;
                    padding: 5px 10px;
                }

                .json-wrap{
                    padding: 20px;

                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    
                    overflow-y: scroll;
                }
            }

            .right-opener{
                grid-area: right-opener;
                background-color: tan;
                cursor: pointer;
                writing-mode:vertical-rl;
                user-select: none;

                &:hover{
                    background-color: yellowgreen;
                }
            }

        }


    }


    .compute-tab-page{
        height: 100%;

        .edit{

            position: absolute;
            height: 100%;
            width: 100%;

            display: grid;
            grid-template-rows: min-content min-content 1fr;
            grid-template-areas: "select-comp" "select-ds" "content";


            .select-computation{
                background-color: #ffffff; //lighten($secondary, 40);
                grid-area: select-comp;
                //border: 1px solid red;
                padding: 0;

                .mini-tab{
                    padding: 4px 15px;
                    border-radius: 5px 10px 0 0;
                    display: inline-block;  
                    color: #cfcfcf;
                    
                    &.sel{
                        background-color: lighten($secondary, 20);
                        font-weight: bold;
                        color: #ffffff;
                    }

                    &.plus{
                        border: 1px solid #cfcfcf;
                        border-bottom: 0;
                    }

                    
                }
            }

            .select-dataset{
                grid-area: select-ds;
                //border: 1px solid green;
                border-bottom: 1px solid #ffffff;
            }

            .edit-content{
                //border: 1px solid blue;
                grid-area: content;
                position: relative;
            }

        }
        .view{
            width: 100%;
            height: 100%;

            display: flex;
            align-items: center;
            justify-content: center;

            color: #afafaf;
        }
    }

}