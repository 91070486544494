@import "../../styles/colors.scss";

.collection-entry-tile{
    //background-color: $gray2;
    
    font-size: 10pt;
    user-select: none;


    border: 1px solid $gray2;
    position: relative;
    padding: 10px;
    //user-select: none;
    
    overflow: hidden;
    height: 80px;

    display: grid;
    grid-template-columns: min-content 1fr;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'image id' 'image label' 'image info';

    &.static-height{
        height: 100px;
    }
  
    

    .btn-delete{
        font-size: 8pt;
        display: none;
        //display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -5px;
        top: -5px;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        color: #202020;
        &:hover{
            background-color: #202020;
            color: #cfcfcf;
        }
        
    }

    // .fill{
    //     grid-area: image;
    //     border: 1px solid #cfcfcf;
    //     width: 100%;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     flex-direction: column;
    //     font-size: 8pt;
    //     background-color: #ffffff;
    //     //color: #404040;

    //     .label{
    //         margin-bottom: 5px;
    //     }
    // }



    .image{
        grid-area: image;
        border: 1px solid #cfcfcf;
        width: 80px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 8pt;
        background-color: #ffffff;
        color: #404040;

        img{
            width: 70px;
            height: 50px;
            //object-fit: cover;
            object-fit: contain;
        }
    }

    .id{
        //grid-area: id;
        position: absolute;
        top: 0px;
        left: 0px;
        font-size: 7pt;
        padding: 2px 5px;
        //border: 1px solid #cfcfcf;
        border-left: 0;
        border-top: 0;
        //border-radius: 0 0 5px 5px;
        background-color: $gray2;
        color: #606060;

        //font-weight: bold;
        // &::before{
        //     content: "[";
        // }
        // &::after{
        //     content: "]";
        // }
    }

    .label{
        grid-area: label;
        font-size: 10pt;
        padding: 5px 10px;
        
    }

    .info{
        grid-area: info;
        font-size: 8pt;
        color: #afafaf;
        padding: 0px 10px;
    }






    &:not(.hide-hover){
        &:hover{
            background-color: $gray2;
            border: 1px solid #afafaf;
            .btn-delete{
                display: inline-flex;
            }
        }
    }

    &.sel{
        background-color: #000000;
        &:hover{
            background-color: #000000;
        }
    }
}