@import "../../../styles/colors.scss";

.generic-resource-input{
    border: 1px solid #cfcfcf;

    //border: 2px solid red;
    position: relative;
    padding: 5px 10px 5px 5px;
    user-select: none;
    background-color: #ffffff;
    

    display: grid;
    grid-template-columns: min-content 1fr;
    //grid-template-rows: 22px;
    grid-template-areas: 'icon label';

    >.icon{
        grid-area: icon;

        display: inline-flex;
        align-items: center;
        justify-content: center;
        //border: 1px dashed teal;

        width: 30px;
    }

    >.label{
        grid-area: label;
        
    }

    >.btn-del{
        position: absolute;
        top: -5px;
        right: -5px;
        visibility: hidden;
    }

    &.sel{
        background-color: $primary;
    }

    &:hover{
        background-color: $primary;
        color: #ffffff;
        >.btn-del{
            color: #404040;
            visibility: visible;
        }
    }

        
    
}

.generic-resource-input-list{
    font-size: 9pt;

    .entry{
 
        border-bottom: 1px solid #dfdfdf;

        //border: 2px solid red;
        position: relative;
        padding: 5px 10px 5px 5px;
        user-select: none;

        display: grid;
        grid-template-columns: min-content 1fr;
        //grid-template-rows: 22px;
        grid-template-areas: 'icon label';

        >.icon{
            grid-area: icon;

            display: inline-flex;
            align-items: center;
            justify-content: center;
            //border: 1px dashed teal;

            width: 30px;
        }

        >.label{
            grid-area: label;
            
        }

        >.btn-del{
            position: absolute;
            top: -5px;
            right: -5px;
            visibility: hidden;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
        }

        &:hover{
            background-color: #404040;
            color: #ffffff;
            >.btn-del{
                color: #404040;
                visibility: visible;
            }
        }

    }

}