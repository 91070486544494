@import "../../../styles/colors.scss";

.select-resource-box{
    background-color: #ffffff;
    border: 1px solid #cfcfcf;

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    font-size: 9pt;

    .resource-type{
        width: 200px;
        padding: 5px 10px;
        border-bottom: 1px solid #cfcfcf;

        &.sel{
            background-color: $primary;
                //color: #ffffff;
        }
        
        &.disabled{
            color: #afafaf;
        }

        &:not(.disabled){
            &:hover{
                background-color: $primary;
                color: #ffffff;
                user-select: none;
            }
        }
    }

    .center{
        //width: 500px;
        //height: 300px;
        //border: 5px solid red;
        border: 1px solid #cfcfcf;
        overflow-y: scroll;
    }

    .resource-item{
        font-size: 9pt;
        //width: 300px;
        padding: 5px 10px;
        border-bottom: 1px solid #cfcfcf;

        &.sel{
            background-color: $primary;
                //color: #ffffff;
        }

        &:hover{
            background-color: $primary;
            color: #ffffff;
            user-select: none;
        }
    }

}