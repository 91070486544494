@import "../../styles/colors.scss";

.top-dashboard .section{
    &.user{

        //width: 300px;
        grid-area: user-section;
        //border: 1px solid blue;

        display: grid;
        grid-template-columns: 1fr min-content;
        grid-template-rows: min-content min-content;
        grid-template-areas: 'name-etc photo' 'bottom bottom';

        .name-etc{
            grid-area: name-etc;

            .name{
                font-size: 10pt;
                margin-bottom: 5px;
                .text{
                    display: inline-block;
                    background-color: $primary;
                    color: $primary-fg;
                    padding: 2px 15px;
                }

            }

            .account{
                font-size: 9pt;
            }
        }

        .photo{
            grid-area: photo;
            
            .photo-border-outer{
                display: inline-flex;
                align-items: center;
                justify-content: center;
                min-width: 100px;
                min-height: 100px;

                border: 1px solid #cfcfcf;
                padding: 5px;

                
            }

            img{

            }
        }

        .bottom{
            grid-area: bottom;
            //border: 1px solid black;
            font-size: 9pt;
        }

        .achievements-strip{
            margin-top: 5px;
            .btn-achievement{
                display: inline-block;
                font-size: 10pt;
                background-color: #efefef;
                padding: 3px 5px;
                margin-right: 5px;
                margin-bottom: 5px;
                color: #000000;
            }
        }


        .team-list{
            font-size: 9pt;
            margin-top: 10px;

            >.team-item{
                user-select: none;
                display: block;
                text-decoration: none;
                color: #404040;
                padding: 5px 10px;
                &:not(.view-more){
                    border-bottom: 1px solid #cfcfcf;
                }
                &.view-more{
                    color: $primary;
                    margin-top: 10px;
                }
                
                &:hover{
                    background-color: $primary;
                    color: $primary-fg;
                }
            }
        }
    }
}
