@import "../../styles/colors.scss";

.project-content-doctree{
    //border: 2px solid tan;
    .doctree-node{
        user-select: none;
        //border: 1px dashed purple;
        font-size: 9pt;

        .doctree-label{
            display: flex;
            flex-direction: row;
            align-items: center;
            //border: 1px dashed red;
            //border-left: 1px dashed #808080;

            // border: 2px solid transparent;
            &.sel{
            //     border: 2px solid lighten($primary, 20%);
                background-color: lighten($primary,20%);
            }

            .expander{
                padding: 4px 6px;
                display: flex;
                align-items: center;
                
                .ch-count{
                    margin-left: 5px;
                    font-size: 8pt;
                }

                //border: 1px dashed red;
                &:hover{
                    background-color: #202020;
                    color: #efefef;
                }
            }

            .label{
                padding: 4px 6px;
                flex-grow: 1;
            }

            .lock{
                padding: 4px 6px;
                font-size: 8pt;

                .locked{
                    color: #808080;
                }
                .unlocked{
                    color: rgba(255,255,255,0.4);
                }

                &:hover{
                    background-color: #202020;
                    color: #efefef;
                }

            }

            .vis{
                padding: 4px 6px;
                font-size: 8pt;

                .hidden{
                    color: #606060;
                }
                .visible{
                    color: rgba(0,0,0,0.2);
                }

                &:hover{
                    background-color: #202020;
                    .hidden{
                        color: #efefef;
                    }
                    .visible{
                        color: #afafaf;
                    }
                }

            }

            &:hover{
                background-color: $primary;
                color: $primary-fg;
            }

            .menu-btn{
                padding: 4px 6px;
                &:hover{
                    background-color: lighten($primary,20%);
                //color: #efefef;
                }
            }
        }
    }

}