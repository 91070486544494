@import "../../styles/colors.scss";

.env-tag{
    background-color: rgba(0,0,0,0.5);
    color: #efefef;
    margin-left: 5px;
    padding: 1px 4px;
    font-size: 7pt;
    text-transform: uppercase;
    border-radius: 3px;
}

.revision-id{
    //color: yellowgreen;
    // background-color: #817f6d;
    // color: #ffffff;
    // padding: 0px 4px;
    color: rgba(130, 110, 80, 0.5)
}

.ds-revision-selector{
    user-select: none;
    
    display: inline-grid;
    grid-template-rows: min-content 1fr;
    // grid-template-columns: min-content min-content min-content 1fr min-content;
    // grid-template-areas: 
    //     'title title title title title' 
    //     'devpill prodpill minus select plus';
    grid-template-columns: 1fr min-content min-content min-content min-content;
    grid-template-areas: 
        'title title title title title' 
        'select devpill prodpill minus plus';

    // background-color: lighten($secondary, 20);
    // color: $secondary-fg;

    background-color: #ffffff;
    //border-bottom: 1px solid #cfcfcf;
    color: #404040;

    //padding: 5px 5px;
    //border: 1px solid darken($secondary, 10);

    .selector-title{
        grid-area: title;
        font-size: 7pt;
        text-transform: uppercase;;
        margin-bottom: 3px;
    }

    .minus{ grid-area: minus; }
    .plus{ grid-area: plus; }
    .minus,.plus{
        display: inline-flex;
        display: none;
        align-items: center;
        height: 23px;
        padding: 0 10px;
        &:hover{
            //background-color: rgba(255, 255, 255, 0.2);
            background-color: rgba(0,0,0, 0.2);
        }
    }

    .selection{
        grid-area: select;
        display: inline-flex;
        align-items: center;
        //background-color: rgba(0, 0, 0,0.2);
        background-color: #efefef;
        height: 100%;
        border: 1px solid #cfcfcf;
        padding: 4px 10px;

        overflow: hidden;

        &:hover{
            background-color: rgba(0, 0, 0, 0.2);
        }

        .text{
            display: inline-block;
            margin-left: 5px;
            white-space: nowrap;
            //text-overflow: clip;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    

    .env-pill{
        //display: inline-flex;
        display: none;
        align-items: center;
        
        //border: 1px solid #dfdfdf;
        border: 1px solid rgba(0, 0, 0, 0.1);

        border-radius: 3px;
        padding: 2px 7px;
        font-size: 7pt;
        margin-right: 5px;
        //color: #cfcfcf;
        //color: rgba(255,255,255,0.2);

        color: rgba(0, 0, 0, 0.2);
        text-transform: uppercase;

        user-select: none;
        cursor: pointer;

        &.prod{
            grid-area: prodpill;
            &.on,&:hover{
                background-color: #000000;
                color: #cfcfcf;// $primary-fg;
                border: 1px solid #000000;
            }
        }
        &.dev{
            grid-area: devpill;
            &.on,&:hover{
                background-color: #202020;// $tertiary;
                color: #cfcfcf;// #404040;
                border: 1px solid #000000;
            }
        }

        // &:hover{
        //     background-color: $primary;
        //     color: $primary-fg;
        // }
    }

    .mini-env-pill{
        display: inline-flex;
        align-items: center;
        
        margin-right: 5px;
        color: rgba(0, 0, 0, 0.2);
        text-transform: uppercase;

        user-select: none;
        cursor: pointer;

        &.prod{
            &.on,&:hover{
                color: #000000;
            }
        }
        &.dev{
            &.on,&:hover{
                color: #000000;
            }
        }

        // &:hover{
        //     background-color: $primary;
        //     color: $primary-fg;
        // }
    }

    
}