.load-pipeline-win{

    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: "header" "main" "footer";
    font-size: 12px;

    .header{
        grid-area: header;
        padding: 10px 20px;
        .title{
            font-size: 14px;
        }
        border-bottom: 1px solid #cfcfcf;
    }

    .main{
        grid-area: main;
        padding: 0;
        //border: 1px solid red;

        display: grid;
        grid-template-rows: 1fr min-content;
        grid-template-areas: "list" "filename";

        .list{
            grid-area: list;
            padding: 0;
            overflow-y: scroll;
            border-bottom: 1px solid #cfcfcf;

            .table-filenames{
                width: 100%;
                border-spacing: 0;

                th, td{
                    border-bottom: 1px solid #cfcfcf;

                    padding: 5px 10px;
                    text-align: left;

                    &.label{
                        
                    }

                    &.modified{
                        width: 200px;
                    }

                }

                td{
                    &.actions{
                        .btn1{
                            padding: 5px 10px;
                            &:hover{
                                background-color: #202020;
                                color: #efefef;
                            }
                        }
                    }
                }

                th{
                    border-bottom: 2px solid #cfcfcf;
                }
            }

            .item{
                user-select: none;
                

                padding: 5px 10px;
                border-bottom: 1px solid #cfcfcf;
                &.sel{
                    background-color: #dfdfdf;
                }

                &:hover{
                    background-color: #efefef;
                }

                
            }

        }

        .filename{
            grid-area: filename;
            padding: 10px 20px;

            .tb-filename{
                width: 100%;
            }
        }
        

    }

    .footer{
        grid-area: footer;
        padding: 10px 20px;

        display: flex;
        flex-direction: row;
        gap: 10px;
        justify-content: flex-end;

        border-top: 1px solid #cfcfcf;
        background-color: #efefef;
    }
}