@import "../../styles/colors.scss";


.data-source-revision-box{

    display: grid;

    border: 1px solid rgba(0,0,0,0.1);

    &.mounted{
        // background-color: #fafafa;
        // border: 1px solid rgba(0,0,0,0.5);
    }

    //border: 1px solid #cfcfcf;
    
    //margin-bottom: 10px;

    grid-template-columns: min-content 1fr min-content;
    grid-template-rows: 1fr;

    grid-template-areas: 'id content menu';

    .rev-status{
        margin-bottom: 5px;
    }

    .rev-number{
        position: relative;
        grid-area: id;
        //border: 1px dashed teal;
        //background-color: #efefef;
        background-color: rgba(0,0,0,0.01);
        //color: #ffffff;
        color: #202020;
        font-weight: bold;

        padding: 10px;

        .compare-button{
            position: absolute;
            bottom: 10px;
            left: 0;
            width: 100%;
            //border: 1px dashed red;
            display: flex;
            align-items: center;
            justify-content: center;
            
            

            .compare-link{
                padding: 3px 5px;
                //border: 1px dashed blue;
                color: #202020;
                &:hover{
                    background-color: rgba(0,0,0,0.1);
                }
            }
        }
    }
    &.active{
        .rev-number{
            background-color: $primary;
            color: $primary-fg;
        }
    }

    .mount-pill{
        background-color: rgba(255,255,255,0.9);
        border: 1px solid #cfcfcf;
        border-radius: 3px;
        padding: 2px 7px;
        font-size: 7pt;
        //margin-left: 5px;
        color: #707070;
        text-transform: uppercase;

        .icon{
            margin-right: 5px;
        }

        &.mounted{
            border: 1px solid #808080;
            color: #404040;
            .icon{
                color: #000000;
            }
        }
        &.not-mounted{
            border: 1px solid #efefef;
            color: #afafaf;
        }

        &:hover{
            //background-color: #ffffff;
            border: 1px solid #808080;
            color: #000000;
        }
    }

    .inspect-pill{
        background-color: rgba(0,0,0,0.3);
        border: 1px solid rgba(0,0,0,0.3);
        border-radius: 3px;
        padding: 2px 7px;
        font-size: 7pt;
        margin-left: 5px;
        color: rgba(255,255,255,0.3);
        text-transform: uppercase;

        .icon{
            margin-right: 5px;
        }

        &.inspected{
            //background-color: greenyellow;
            background-color: #ffffff;
            border: 1px solid #808080;
            color: #000000;
            // .icon{
            //     color: #000000;
            // }
        }
        &.not-inspected{
            background-color: #ffffff;
            border: 1px solid #efefef;
            color: #afafaf;
        }

        &:hover{
            //background-color: #ffffff;
            border: 1px solid #808080;
            color: #000000;
        }
    }

    .env-pill{
        
        //border: 1px solid #dfdfdf;
        border: 1px solid rgba(0,0,0,0.2);

        border-radius: 3px;
        padding: 2px 7px;
        font-size: 7pt;
        margin-left: 5px;
        //color: #cfcfcf;
        color: rgba(0,0,0,0.2);
        text-transform: uppercase;

        user-select: none;
        cursor: pointer;

        &.prod{
            &.on,&:hover{
                background-color: #000000;
                color: #cfcfcf;// $primary-fg;
                border: 1px solid #000000;
            }
        }
        &.dev{
            &.on,&:hover{
                background-color: #202020;// $tertiary;
                color: #cfcfcf;// #404040;
                border: 1px solid #000000;
            }
        }

        // &:hover{
        //     background-color: $primary;
        //     color: $primary-fg;
        // }
    }

    .rev-content{
        grid-area: content;
        padding: 10px;

        .status{
            display: block;
            //color: ;
            padding: 5px 0;
        }
    }

    .rev-notes{
        grid-area: title;
        font-size: 10pt;
        //padding: 10px;
        margin-bottom: 17px;
        //border-bottom: 1px solid #cfcfcf;
        //border-bottom: 1px solid rgba(255,255,255,0.1);
        

        //border: 1px solid teal;
        padding: 10px 0;
        //color: lighten(teal,60);
        //color: #808080;
        color: $primary;
    }

    .btn-filename-download{
        //border: 1px solid #efefef;
        padding: 2px 6px 2px 0;
        user-select: none;
        //margin-left: 5px;
        font-size: 12px;

        transition: all ease 0.25s;
        i{
            margin-left: 5px;
            padding: 2px 5px;
        }

        &:hover{
            //font-weight: bold;
            //text-decoration: underline;
            background-color: #efefef;
            padding: 2px 8px;
        }
    }

    .rev-filename{
        //font-family: 'Courier New', Courier, monospace;
        .label{
            display: none;
            color: teal;
            //color: #ffffff;
            font-family: arial;
        }
        //color: rgba(255,255,255,0.7);
        //color: lighten(teal,10);
        color: #202020;
        font-size: 9pt;
        margin-bottom: 7px;
        .size{
            font-size: 8pt;
            font-style: italic;
            float: right;
            color: #cfcfcf;
            font-family: arial;
        }
        
    }

    .rev-menu{
        grid-area: menu;
        white-space: nowrap;
        text-overflow: clip;
        
        
    }
    
    .rev-created{
        color: #808080;
        //color: yellow;
        font-style: italic;
        font-weight: 300;
        font-size: 8pt;
    }

}

