@import "../../styles/colors.scss";

.data-source-revisions{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'header' 'table';


    .actions-header{
        grid-area: header;
        padding-bottom: 10px;
        padding-left: 20px;
        //margin-bottom: 10px;
        border-bottom: 1px solid #cfcfcf;
    }

    .revisions-table{
        position: relative;
        grid-area: table;
        
        //background-color: $secondary;
        background-color: #ffffff;
         
        overflow-y: auto;
        padding: 10px 20px;

        .width-limit{
            //max-width: 500px;
        }

        .message{
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0,0,0,0.1);
            display: inline-block;
            color: #202020;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }


        .rev-separator{
            text-align:center;
            display: flex;
            flex-direction: column;
            align-items: center;

            .dots{
                display: inline-block;
                border-left: 1px dashed #808080;
                height: 10px;
            }
            .compare-link{
                text-decoration: none;
                color: #4090f0;
                padding: 5px;
            }
        }
   
    }
}