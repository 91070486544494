@import "../../styles/colors.scss";

.login-page{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: hidden;

    xbackground-image: // //rgba(255,255,0,0.4), rgba(205, 236, 205, 0.3));
        //linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(205, 236, 205, 0.3)); //$primary
        //linear-gradient(to bottom right, rgba(255,255,255,0.4), $secondary);
        linear-gradient(to bottom right, rgba(255,255,255,0.4), rgba(20,150,220,0.9));
    

    display: flex;
    align-items: center;
    justify-content: center;

    .lower-half{
        position: absolute;
        left: 0;
        width: 100%;
        top: 50%;
        height: 50%;
        
        z-index: 0;
        border-top: 8px solid lighten($secondary, 50%);
        //background-color: $secondary;
        background-color: #0e78b0;
        // border-top: 8px solid lighten(#0e78b0, 40%);
    }

    .form-wrapper{
        //border: 4px solid red;
        z-index: 1;
        width: 100%;
        height: 100%;

        // display: flex;
        // align-items: center;
        // justify-content: center;
        overflow-y: auto;
        
        

        .form-area{
            margin: 20px auto;
            width: 400px;
            max-width: 100%;

            //border: 4px solid black;
            z-index: 1;

            .error{
                background-color: #efaaaa;
                padding: 10px;

                i{
                    font-size: 20px;
                }

                max-width: 300px;
                color: #000000;
                font-weight: 500;
            }
            
            .credentials-form{
                padding: 20px 40px;
                //border: 1px dashed teal;
                background-color: #ffffff;
                box-shadow: 0 2px 6px rgba(0,0,0,0.5);

                .logo{
                    text-align: center;
                    display: block;
                    font-size: 10pt;
                    text-decoration: none;
                    //color: #afafaf;
                    color: #808080;
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    font-weight: 500;
                    margin: 20px 0;
                }

                .go-back{
                    display: block;
                    text-decoration: none;
                    color: #808080;
                    margin: 10px 0 0 0;
                    user-select: none;
                }
                
                h1{
                    font-size: 17pt;
                    font-weight: 400;
                    padding: 0;
                    margin: 0 0 10px 0;
                }

                .entry{
                    margin: 15px 0;

                    .label{
                        margin: 0 0 5px 0;
                        max-width: 300px;

                        .new-password-note{
                            font-size: 10px;
                            color: lighten($primary,20);
                        }

                        
                    }

                    .new-password-rule-check{
                        font-size: 10px;
                        padding: 5px 10px;
                        margin-top: 5px;
                        .icon{
                            margin-right: 5px;
                        }
                        &.pass{
                            background-color: #ffffff;// #b0e6b0;
                            .icon{
                                color: #387538;
                            }
                        }
                        &.fail{
                            background-color: #ffaaaa;
                            .icon{
                                color: #990000;
                            }
                        }
                    }
                }

                input[type="text"], input[type="password"]{
                    width: 300px;
                }

                .password-row{
                    display: flex;
                    flex-direction: row;
                    input[type="text"], input[type="password"]{
                        width: 100%;
                        flex-grow: 1;
                    }
                    .eyeball{
                        user-select: none;
                        //border: 1px dashed red;
                        font-size: 11px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 0 5px;
                        &:hover{
                            background-color: #efefef;
                        }
                    }
                }

                .submit-section{
                    margin-top: 20px;
                    //text-align: right;
                    display: flex;
                    align-content: stretch;

                    .forgot-password{
                        flex: 1;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                        a{
                            text-decoration: none;
                            color: #808080;
                            &:hover{
                                text-decoration: underline;
                                color: $primary-dark;
                            }
                        }
                    }

                    button{
                        background-color: $secondary;
                        color: $secondary-fg;
                        border: none;
                        padding: 7px 20px;

                        &.left-margin{
                            margin-left: 10px;
                        }

                        &:hover{
                            &:not(:disabled){
                                background-color: $primary;
                                color: $primary-fg;
                            }
                        }

                        &:disabled{
                            opacity: 0.5;
                        }
                    }
                }

                .signup-section{
                    border-top: 1px solid #cfcfcf;
                    margin: 20px 0;
                    padding-top: 20px;
                    text-align: center;
                    
                    a{
                        display: inline-block;
                        background-color: #ffffff;
                        color: #404040;
                        margin-left: 10px;
                        //border: 1px solid #cfcfcf;
                        padding: 7px 20px;
                        text-decoration: none;

                        &:hover{
                            background-color: $primary;
                            color: $primary-fg;
                        }

                        &:disabled{
                            opacity: 0.5;
                        }
                    }

                }

                .footer-section{
                    border-top: 1px solid #cfcfcf;
                    margin: 20px 0;
                    padding-top: 20px;
                    text-align: center;
                    
                    a{
                        display: inline-block;
                        background-color: #ffffff;
                        color: #404040;
                        text-decoration: none;

                        &:hover{
                            color: $primary-dark;
                        }

                        &:disabled{
                            opacity: 0.5;
                        }
                    }
                }
            }
        }
    }

    .version-info{
        color: #afafaf;
        display: block;
        margin-top: 15px;
        font-size: 10px;
        font-family:'Courier New', Courier, monospace;
        .name{
            color: #000000;
        }
        .val{

        }
    }
}