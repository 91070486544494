@import "../../styles/colors.scss";

.new-page-form{
    //border: 1px dashed crimson;

    display: grid;
    grid-template-columns: minmax(350px, 1fr) 400px;
    grid-template-areas: 'right left';

    .left{
        grid-area: left;
        margin-left: 15px;
    }

    .right{
        grid-area: right;
        
    }

    .btn1{
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        border: 1px solid #cfcfcf;
        width: 70;
        max-width: 70;
        overflow: hidden;
        // height: 40;
        padding: 10px;
        font-size: 9pt;
        .icon{
            display: block;
        }
        .label{
            display: none;
        }
    }

    .template-list{
        border: 1px solid #cfcfcf;
        padding: 10px;
        font-size: 9pt;
        height: 300px;
        overflow-y: scroll;

        .template{
            user-select: none;
            padding: 5px 10px;
            border-bottom: 1px solid #cfcfcf;
            .label{
                padding: 0 0 0 10px;
            }
            &.sel{
                background-color: $primary;
                color: #ffffff;
            }
            &:hover{
                background-color: $primary;
                color: #ffffff;
            }
        }

        .folder{
            user-select: none;
            padding: 5px 10px;
            border-bottom: 1px solid #cfcfcf;

            &:hover{
                background-color: $primary;
                color: #ffffff;
            }

            .expander{
                padding: 5px 10px;
            }

            .label{
                padding: 0;
            }
        }
    }

}