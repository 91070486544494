@import "../../styles/colors.scss";

.calc-diff-tool{

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;

    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: minmax(0, max-content) minmax(0, max-content) 1fr;
    grid-template-areas: 
        'settings settings settings' 
        'input1 input2 calc-note'
        'input1 input2 output';
    
    
    .settings{
        grid-area: settings;
        padding: 5px 10px;
    }

    .input1{
        grid-area: input1;
        position: relative;
        width: 400px;
    }

    .input2{
        grid-area: input2;
        position: relative;
        width: 400px;
    }

    .calc-note{
        grid-area: calc-note;
        padding: 5px 10px;
        background-color: $primary;
        color: $primary-fg;
    }

    .output{
        grid-area: output;
        position: relative;

        display: grid;
        grid-template-rows: min-content 1fr;
        grid-template-areas: 'header' 'content';

        >.header{
            grid-area: header;
        }
        
        >.content{
            grid-area: content;
            position: relative;
        }


    }

}