.field-search{
    border: 1px solid #cfcfcf;
    display: grid;

    grid-template-columns: min-content 1fr min-content min-content;
    grid-template-areas: 'icon main options cancel';

    .icon{
        grid-area: icon;
        display: flex;
        align-items: center;
        width: 30px;
        i{
            margin: auto;
        }
    }

    .search-main{
        grid-area: main;

        display: inline-block;
        background-color: #efefef;
        //padding: 1px;
        //border: 1px solid red;

        .tb1{
            border: 0;
            font-size: 9pt;
            width: 100%;

            &:focus{
                outline: none;
            }
        }
    }   

    .options{
        display: inline-flex;
        align-items: center;
        float: right;
        
        font-size: 7pt;

        .btn{
            height: 100%;
            display: inline-flex;
            align-items: center;
            width: 30px;
            //border: 1px dashed red;
            .center,i{
                margin: auto;
            }
            &:hover{
                background-color: #cfcfcf;
            }
        }

    }
    
    .cancel{
        grid-area: cancel;
        border-left: 1px solid #cfcfcf;

        display: flex;
        align-items: center;
        width: 30px;
        i{
            margin: auto;
        }
    
        &:hover{
            background-color: #cfcfcf;
        }
    }

    
}