@import "../../styles/colors.scss";

.att-input-bool{
    font-size: 9pt;
    white-space: nowrap;
    text-overflow: clip;

    label{
        display: block;
        padding: 5px 10px;
    }

    &:hover{
        background-color: $primary;
    }
}

.contin-att-edit-dlg{
    padding: 15px;
    
    .title{
        color: #003264;
        font-size: 9pt;
        margin-bottom: 3px;
        user-select: none;
    }

    .text-section{
        margin-top: 10px;
    }
    
    .edit-box{
        width: 100%;
        height: 100%;
        text-align: center;
        border: 1px solid #cfcfcf;
    }

    .adjust-btn{
        color: #afafaf;
        font-size: 9pt;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 10px;
        //border: 1px solid #cfcfcf;
        &.dec{
            margin-right: -1px;
        }
        &.inc{
            margin-left: -1px;
        }
        &:hover{
            background-color: $primary;
            color: #ffffff;
        }
    }

    .slider-section{
        margin-top: 10px;
    }

    .domain-value{
        font-size: 8pt;
        text-align: center;
        &.min{
            padding-right: 10px;
        }
        &.max{
            padding-left: 10px;
        }
    }

    .edit-slider{
        width: 100%;
    }

    .bottom-buttons{
        text-align: center;
    }
}

.att-input{
    padding: 0;
    //border: 1px dashed teal;
    border-right:1px solid #cfcfcf;

    &.sel{
        background-color: $primary;
        color: $primary-fg;
    }


    &:focus-within {
        //outline: none;
        outline: 1px solid $primary;
        //border-style: unset;
        //border: 1px solid orange;
    }

    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover{
        .att-select{
            color: #cfcfcf;
        }
    }

    .att-select{
        padding: 5px 6px 5px 5px;
        //border: 1px solid red;
        color: #efefef;
        color: transparent;
        &:hover{
            color: $primary;
            //background-color: #efefef;
        }

        &.sel{
            background-color: $primary;
            color: #ffffff;
        }
    }

    // &.sel{
    //     background-color: $primary;
    //     color: $primary-fg;
    // }

    

    >.att-value{
        flex-grow: 1;
        grid-area: center1;
        font-size: 9pt;
        padding: 0;
        //padding: 0px 10px;
        //border: 5px solid blue;

        >select{
            
            width: 100%;
            padding: 5px 10px;
            font-size: 9pt;
            border: 1px solid #cfcfcf;
            //border: 0;
            background-color: rgba(255,255,255,0.7);

            &:disabled{
                opacity: 1.0; // this is to fix a weird chrome bug where opacity<1 causes select to show through sticky header
            }
        }

        >input{
            width: 100%;
            padding: 5px 10px;
            font-size: 9pt;
            border: 1px solid #cfcfcf;
            //background-color: red; //rgba(255,255,255,0.7);
            //background-color: transparent;
        }

        // >:input[type="checkbox"]{
        //     border: 1px solid red;
        // }

        label{
            display: block;
            width: 100%;
            //border: 1px solid red;
            padding: 5px 0;
            &:hover{
                background-color: #1e90ff;
                color: #ffffff;
            }
            
        }
    }

    .att-edit-btn{
        color: transparent;
        padding: 5px;
        grid-area: right;
        //border: 1px solid purple;

        &:hover{
            background-color: #efefef;
            color: #000000;
        }
    }
    &:hover{
        .att-edit-btn{
            color: unset;
        }
    }

    .att-dirty-bit{
        font-size: 5pt;
        padding: 5px;
        color: #efefef;
        .dirty{
            color: $secondary;
        }
        &:hover{
            .dirty{
                color: $primary;
            }
        }
    }

    // &:hover{
    //     //border: 1px solid $primary;
    //     background-color: #efefef;
    //     .att-select{
    //         display: inline;
    //         color: #afafaf;
    //     }
    // }


    &.sep{
        border-bottom: 1px solid $gray2;
    }
        
    >.att-label{
        color: #003264;
        font-size: 9pt;
        margin-bottom: 3px;
        user-select: none;
        //padding: 0px 10px;
    }

    .contin-box{
        //border: 1px solid #cfcfcf;
        border: 0;
        //background-color: #ffffff;
        //padding: 5px 10px;
        //color: #404040;
        text-align: center;
        user-select: none;
        input{
            background-color: transparent;
            border: 0;
            text-align: center;
            width: 100px;
            &:focus {
                outline: none;
                //border-style: unset;
                //border: 1px solid orange;
            }
        }

    }

    
}