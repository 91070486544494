
$left-menu-width: 350px;

// NOTE: also set this in globals
$left-menu-collapse-point: 900px;
$left-menu-collapse-point2: 1400px;
// end of also set in globals

$right-menu-width: 350px; // new?

$bottom-bar-height: 80px;