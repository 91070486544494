@import "../../styles/zIndex.scss";

.export-item-wrapper{
    border: 0px solid;
    position: relative;
    //margin: 1px;
    //padding: 10px;
    //transition: 0.5s ease all;
    transition: all 0.25s ease;

    &.inline-block{
        display: inline-block;
    }

    &.fill{
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        //border: 5px solid red;

    }

    >.ctype{
        z-index: $z-index-export-actions;
        //transition-delay: 0.5s;
        opacity: 0;
        font-size: 9pt;
        color: #000000;
        //padding: 4px 8px;
        //background-color: darkcyan;
        background-color: #fafafa;
        display: inline-block;
        //border-radius: 0 0px 10px 10px;
        padding: 5px 10px;
        
        position: absolute;
        right: 0px; //-13px;
        top: -5px;
        //background-color: red;

    }

    // &.page-style{
    //     >.ctype{
    //         position: absolute;
    //         left: 0px; //-13px;
    //         width: 100%;
    //         top: -5px;
    //         border-radius: 0;
    //         opacity: 1.0;
    //         //text-align: right;
            
    //     }
    //     margin: 0;
    //     padding: 35px 10px 10px 10px;
    //     border-width: 5px 5px 5px 5px;
    // }

    .open,&:hover{
        //border-width: 5px;
        border-width: 0px;

        // margin: 0px;
        // padding: 35px 10px 10px 10px;
        
        >.ctype{
            opacity: 1.0;
            
            //color: #ffffff;
        }
    }


    >.ctype{
        >.smallbtn{
            padding: 5px 8px;
            &:hover{
                background-color: rgba(0,0,0,0.2);
                //color: #000000;
            }
            &.sel{
                background-color: rgba(0,0,0,0.8);
                color: #ffffff;
                
            }
            &.disabled{
                opacity: 0.3;
                background-color: unset;
            }
            
        }
    }


    
}

