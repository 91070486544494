@import "../../styles/colors.scss";

.swatches-viewbox{
    display: grid;
    grid-template-rows: 0 min-content 1fr;
    grid-template-areas: 'top' 'actions' 'main';

    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;


    .top-header{
        grid-area: top;
        display: none;
        
        //text-align: right;
        font-size: 9pt;
        padding: 10px;
        border-bottom: 1px solid #cfcfcf;

        .left-section{
            
        }

        .right-section{
            float: right;
        }

        .view-button{
            user-select: none;
            display: inline-block;
            background-color: $gray2;
            padding: 5px 8px;
            margin-right: 5px;

            &.sel{
                background-color: $primary;
                color: #ffffff;
            }
        }
    }

    .actions-header{
        grid-area: actions;
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;
    }

    .list-content{
        grid-area: main;

        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        overflow-y: scroll;

        &.unknown{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9pt;
            color: #808080;
        }

        &.swatches{

            display: grid;
            padding: 10px;
            grid-template-columns: repeat(auto-fill,120px);
            grid-template-rows: repeat(auto-fill,minmax(100px,100px));
            row-gap: 10px;
            column-gap: 10px;


            

        }

    }


}