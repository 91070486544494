@import "../../../../styles/colors.scss";

.edit-skip-stage{

    padding: 5px;

    .value-box{
        user-select: none;
        
        border: 1px solid #cfcfcf;
        padding: 5px 10px;

        &:hover{
            background-color: $primary;
            color: $primary-fg;
        }
    }
}