@import "../../../../styles/colors.scss";

.stages-inspect-button{
    display: flex;
    border: 1px solid #cfcfcf;
    align-items: center;
    padding: 1px;
    //margin: 5px 0;
    button{
        font-size: 8pt;
        background-color: #efefef;
        border: 0;// 1px solid #cfcfcf;
        padding: 4px 7px;
        height: 24px;
        margin-right: 1px;
        &:hover{
            // background-color: $primary;
            // color: $primary-fg;
            background-color: #404040;
            color: #afafaf;
        }
    }
    .spinner{
        padding: 4px 7px;
        margin-right: 1px;
        //background-color: aquamarine;
    }
}