.mstudio-h-dock-layout{
    
    display: grid;
    overflow: hidden;

    //grid-template-columns: minmax(250px, min-content) 1fr minmax(250px, min-content);
    grid-template-columns: min-content 1fr min-content;
    grid-template-areas: 'left center right';
    >.left{ grid-area: left; overflow: hidden }
    >.center{ grid-area: center; overflow: hidden }
    >.right{ grid-area: right; overflow: hidden }
}