@import "../../styles/colors.scss";

.ds-selector{
    user-select: none;

    display: inline-grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 
        'title' 
        'select';

    background-color: #ffffff;
    color: #404040;

    .selector-title{
        grid-area: title;
        font-size: 7pt;
        text-transform: uppercase;;
        margin-bottom: 3px;
    }

    .selection{
        grid-area: select;
        
        display: inline-flex;
        height: 100%;
        align-items: center;
        overflow: hidden;
        
        padding: 4px 10px;
        border: 1px solid #cfcfcf;
        background-color: #efefef;

        &:hover{
            background-color: rgba(0, 0, 0,0.2);
        }

        .id{
            color: yellowgreen;
        }

        .text{
            display: inline-block;
            margin-left: 5px;
            white-space: nowrap;
            //text-overflow: clip;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

   
}