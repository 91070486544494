@import "../../styles/zIndex.scss";
@import "../../styles/colors.scss";
@import "../../styles/metrics.scss";

$button-width: 90px; // note: this must match var in React JSX
$button-height: 80px; // note: this must match var in React JSX; also align bottom-bar-height

.nav-bottom-bar{


    background-color: #ffffff;
    border-top: 1px solid #cfcfcf;
    //overflow: hidden;
    display: grid;
    //grid-template-columns: repeat(auto-fill, minmax($button-width, 1fr));
    min-height: $button-height;
    //max-height: $button-height;
    transition: height 0.3s ease-in-out;

    grid-area: project-main;
    position: absolute;
    bottom: 0px;
    height: $bottom-bar-height;
    width: 100%;

    z-index: $z-index-bottom-bar;

    >.bar-button{
        user-select: none;
        //border: 1px dashed teal;

        //width: $button-width;
        height: $button-height;
        max-height: $button-height;
        overflow: hidden;

        display: inline-flex;
        //align-items: center;
        justify-content: center;
        flex-direction: column;
        
        border-right: 1px solid $gray2;
        
        color: #606060;
        padding: 0 10px;
        font-size: 9pt;
        text-align: center;
        text-decoration: none;

        .glyph{
            font-size: 11pt;
            color: #303030;
            margin-bottom: 3px;
        }

        &:hover{
            //background-color: #000000;

            background-color: lighten($secondary, 20%);
            color: lighten(#afafaf, 20%);
            
            //background-color: $tertiary;
            //color: $tertiary-fg;
            .glyph{
                color: #ffffff;
            }
        }

        &.sel{
            background-color: $secondary;
            color: $secondary-fg;
            .glyph{
                color: #ffffff;
            }
        }

    }
}