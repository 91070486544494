.string-property-editor{
    display: inline-block;
    border: 1px solid #cfcfcf;
    background-color: #ffffff;
    padding: 3px 10px;
    user-select: none;

    .del{
        padding: 3px 7px;
        margin-left: 10px;
        margin-right: -10px;
        background-color: #efefef;
        &:hover{
            background-color: #202020;
            color: #efefef;
        }
    }
}