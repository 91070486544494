@import "../../styles/zIndex.scss";
@import "../../styles/colors.scss";

.modal-frame{
    border: 1px solid #cfcfcf;

    width: 100%;
    height: 100%;
    overflow: hidden;

    background-color: #ffffff;
    z-index: $z-index-modal;

    display: grid;
    grid-template-rows: min-content 1fr min-content;
    grid-template-areas: 'header' 'content' 'footer';
   
    >.modal-frame-header{
        grid-area: header;
        //height: 60px;
        background-color: $gray2;
        text-align: center;
        padding: 10px;
        position: relative;

        .close-button{
            position: absolute;
            right: 5px;
            top: 5px;
            display: inline-flex;
            width: 25px;
            height: 25px;
            //background-color: red;
            align-items: center;
            justify-content: center;

            &:hover{
                background-color: #404040;
                color: #ffffff;
            }

        }

        .help-button{
            font-size: 10pt;
            position: absolute;
            right: 35px;
            top: 5px;
            display: inline-flex;
            width: 25px;
            height: 25px;
            //background-color: red;
            align-items: center;
            justify-content: center;
            text-decoration: none;
            color: #202020;

            &:hover{
                background-color: #404040;
                color: #ffffff;
            }

        }

        .expand-toggle-button{
            position: absolute;
            right: 35px;
            top: 5px;
            display: inline-flex;
            width: 25px;
            height: 25px;
            //background-color: red;
            align-items: center;
            justify-content: center;

            &:hover{
                background-color: #404040;
                color: #ffffff;
            }

        }

        .title{
            font-weight: normal;
            font-size: 12pt;
            margin-bottom: 5px;
        }
        .caption{
            font-size: 9pt;
        }
    }

    >.modal-frame-content{
        grid-area: content;
        width: 100%;
        height: 100%;
        overflow: hidden;
        padding: 20px;
    }

    &.no-content-padding{
        >.modal-frame-content{
            padding: 0;
        }
    }

    >.modal-frame-footer{
        grid-area: footer;
        padding: 15px;
        text-align: center;
        border-top: 1px solid #cfcfcf;
        .button-section{
            
            //float: right;
        }
    }

}

