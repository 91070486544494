@import "../../../../styles/colors.scss";

.edit-filter-stage{


    .filter-entry{
        //border: 1px solid red;
        margin-bottom: 5px;

        display: grid;
        grid-template-columns: min-content 1fr min-content;

        .icon{
            display: flex;
            align-items: center;
            justify-content: center;
            width: 30px;
        }

        .syntax{
            display: inline-block;
            user-select: none;
            border: 1px solid #cfcfcf;
            padding: 5px 10px;
            background-color: #ffffff;
            margin-right: 5px;

            &.new-entry{
                font-style: italic;;
            }

            &:hover{
                background-color: $primary;
                color: $primary-fg;
            }
        }
        
        .actions{
            &.invisible{
                opacity: 0;
            }
        }
    }

    
}

.edit-filter-modal{
    
    width: 100%;
    height: 100%;
    padding: 0;

    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 'top top' 'left main';

    font-size: 9pt;

    .top{
        //border-bottom: 1px solid #cfcfcf;
        grid-area: top;
        margin: 0;
        padding: 0 0 10px 0;

        display: flex;
        align-items: center;

        >.label{
            margin-right: 10px;
        }

        >.tb{
            flex-grow: 1;
        }
    }

    .left{
        grid-area: left;
        margin-right: 5px;

        border: 1px solid #cfcfcf;

        >.top{
            //border: 1px solid red;
            margin: 0;
            padding: 5px;
        }
    }

    .main{
        grid-area: main;
        margin-left: 5px;
        border: 1px solid #cfcfcf;

        .values-select{
            font-size: 9pt;
            width: 100%;
            height: 100%;

            option{
                padding: 5px 10px;
                border-bottom: 1px solid #efefef;
                color: #404040;
                // font-weight: 500;
                // &.string{
                //     //color: orchid;
                //     &::before{
                //         color: #990000;
                //         font-family: "Font Awesome 5 Pro"; font-weight: 500; content: "\f27a";
                //         margin-right: 5px;
                //     }
                // }
                // &.numeric{
                //     &::before{
                //         color: $primary;
                //         //color: #000000;
                //         font-family: "Font Awesome 5 Pro"; font-weight: 500; content: "\f00a";
                //         margin-right: 5px;
                //     }
                // }
                // &::after{
                //     font-weight: normal;
                //     color: rgba(0,0,0,0.5);
                //     //content: "ASDF";
                //     content: attr(data-content);
                //     margin-left: 5px;
                // }
                
            }

            &:focus {
                outline: none;
                //border-style: unset;
                //border: 1px solid orange;
            }
        }
    }
    // .top{
    //     .text1{
    //         border: 1px solid #cfcfcf;
    //         margin-bottom: 10px;
    //         width: 100%;
    //     }
    // }
    
    // .menu1{
    //     top: 0;
    //     left: 0;
    //     position: absolute;
    //     width: 100%;
    //     height: 100%;
    //     border: 1px solid #cfcfcf;
    //     overflow-y: scroll;
    // }
}