
//$primary: #3f83be;

// green color theme
//$primary: #13aa52;
//$primary: #217346;

// $primary-fg: #ffffff;
// $primary-fg2: #efefef;
// $primary-med: #129851;
// $primary-dark: #116149; 

// blue color theme
$primary: #1298df; //rgb(1, 153, 77);
$primary-fg: #ffffff;
$primary-fg2: #efefef;
$primary-med: #1078bf;
$primary-dark: #08589f; 


$secondary: #3d4f58;
//$secondary: lighten($primary, 10%);
$secondary-fg: #efefef;

$tertiary: lighten($secondary, 50%);
$tertiary-fg: lighten(#000000, 20%);
$tertiary-fg2: lighten(#000000, 40%);


//$gray2: #efefef;
$gray2: #f5f6f7;





@mixin inspection-note {
    background-color: #efefef;
    color: #404040;
    font-size: 7pt;
    font-weight: 400;
    padding: 4px 10px;
    text-transform: none;
    //color: teal;
}