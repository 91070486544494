@import "../../styles/colors.scss";

.edit-model{
    font-size: 10pt;

    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: min-content 1fr;
    grid-template-areas: 'fixed-area' 'content-area';
    
    .fixed-area{
        overflow: hidden;
    }

    .content-area{
        overflow: hidden;
    }

    .form-row{
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;

        .property{
            display: inline-block;
            width: 150px;
        }

        .property-value{
            display: inline-block;
        }
    }

    .form-tab-strip{
        user-select: none;

        .tab{
            display: inline-block;
            padding: 10px 20px;
            border-bottom: 2px solid transparent;
            &.sel{
                color: #000000;
                border-bottom: 2px solid $primary;
            }
            &:hover{
                background-color:#000000;
                color: $gray2;
            }
            
        }

    }

    .content-area{
        border-top: 1px solid #cfcfcf;
    }

}