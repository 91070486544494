@import "../../styles/colors.scss";

.collection-entries-viewbox{
    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-areas: 'top' 'actions' 'main';

    position: relative;
    left: 0;
    top: 0;
    height: 100%;
    overflow: hidden;


    .top-header{
        grid-area: top;
        //display: none;
        
        //text-align: right;
        font-size: 9pt;
        padding: 10px;
        border-bottom: 1px solid #cfcfcf;

        .left-section{
            
        }

        .right-section{
            float: right;
        }

        .view-button{
            user-select: none;
            display: inline-block;
            background-color: $gray2;
            padding: 5px 8px;
            margin-right: 5px;

            &.sel{
                background-color: $primary;
                color: $primary-fg;
            }
        }
    }

    .actions-header{
        grid-area: actions;
        padding: 10px 20px;
        border-bottom: 1px solid #cfcfcf;

        .entry-search{
            border: 1px solid #cfcfcf;
            display: inline-grid;
            width: 300px;
            margin-right: 10px;
        
            grid-template-columns: min-content 1fr min-content min-content;
            grid-template-areas: 'icon main options cancel';
        
            .icon{
                grid-area: icon;
                display: flex;
                align-items: center;
                width: 30px;
                i{
                    margin: auto;
                }
            }
        
            .search-main{
                grid-area: main;
        
                display: inline-block;
                background-color: #efefef;
                //padding: 1px;
                //border: 1px solid red;
        
                .tb1{
                    border: 0;
                    font-size: 9pt;
                    width: 100%;
        
                    &:focus{
                        outline: none;
                    }
                }
            }   
        
            .options{
                display: inline-flex;
                align-items: center;
                float: right;
                
                font-size: 7pt;
        
                .btn{
                    height: 100%;
                    display: inline-flex;
                    align-items: center;
                    width: 30px;
                    //border: 1px dashed red;
                    .center,i{
                        margin: auto;
                    }
                    &:hover{
                        background-color: #cfcfcf;
                    }
                }
        
            }
            
            .cancel{
                grid-area: cancel;
                border-left: 1px solid #cfcfcf;
        
                display: flex;
                align-items: center;
                width: 30px;
                i{
                    margin: auto;
                }
            
                &:hover{
                    background-color: #cfcfcf;
                }
            }
        
            
        }

    }

    .list-content{
        grid-area: main;
        
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;

        overflow-y: scroll;

        &.unknown{
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 9pt;
            color: #808080;
        }

        &.tiles{

            display: grid;
            padding: 10px;
            grid-template-columns: repeat(auto-fill,300px);
            grid-template-rows: repeat(auto-fill,minmax(80px,80px));
            row-gap: 10px;
            column-gap: 10px;


            // .tile{
            //     //background-color: $gray2;
            //     position: relative;
            //     font-size: 10pt;
            //     padding: 10px;
            //     //user-select: none;

            //     display: grid;
            //     grid-template-columns: min-content 1fr;
            //     grid-template-rows: min-content 1fr;
            //     grid-template-areas: 'image label' 'image info';

            //     // &.new{
            //     //     display: flex;
            //     //     align-items: center;
            //     //     justify-content: center;
            //     //     margin: 10px;
            //     //     border: 1px dashed #cfcfcf;
            //     //     font-size: 9pt;
            //     // }

            //     .btn-delete{
            //         display: none;
            //         //display: inline-flex;
            //         align-items: center;
            //         justify-content: center;
            //         position: absolute;
            //         right: -5px;
            //         top: -5px;
            //         width: 20px;
            //         height: 20px;
            //         background-color: #ffffff;
            //         border: 1px solid #cfcfcf;
            //         border-radius: 10px;
            //         color: #202020;
            //         &:hover{
            //             background-color: #202020;
            //             color: #cfcfcf;
            //         }
                    
            //     }
                
            //     .image{
            //         grid-area: image;
            //         border: 1px solid #cfcfcf;
            //         width: 80px;
            //         height: 60px;
            //         display: flex;
            //         align-items: center;
            //         justify-content: center;
            //         font-size: 8pt;
            //         background-color: #ffffff;
            //         color: #404040;
            //     }

            //     .label{
            //         grid-area: label;
            //         font-size: 10pt;
            //         padding: 5px 10px;
            //     }

            //     .info{
            //         grid-area: info;
            //         font-size: 8pt;
            //         color: #afafaf;
            //         padding: 0px 10px;
            //     }

            //     &:hover{
            //         background-color: $gray2;
            //         .btn-delete{
            //             display: inline-flex;
            //         }
            //     }

            //     &.sel{
            //         background-color: #000000;
            //         .label{
            //             color: #ffffff;
            //         }
            //     }
            // }

        }

    }

    // .selected-item-pane{
    //     .title-bar{
    //         display: flex;
    //         align-items: center;
    //         justify-content: center;
    //         height: 35px;
    //         border-bottom: 1px solid #cfcfcf;
    //         padding: 0px 20px;
    //         font-size: 10pt;
    //     }

    //     &.monaco{
    //         border: 0;
    //         overflow: hidden;
    //         //position: absolute;
    //     }
    // }


}