@import "../../styles/colors.scss";

.top-bar{
    //background-color: #000000;
    background-color: $secondary;
    color: $gray2;
    padding: 0;

    grid-area: topbar;

    // position: absolute;
    // left: 0;
    // top: 0;
    // width: 100%;
    // height: 30px;

    display: grid;
    grid-template-columns: 1fr min-content min-content;
    grid-template-rows: 1fr;
    grid-template-areas: 'left-section middle-section right-section';


    
    .left-section{
        grid-area: left-section;
        white-space: nowrap;
        text-overflow: clip;
        
        width: 100px;
        display: flex;
        align-items: center;
        padding: 0px 10px;

        font-size: 11pt;

        .logo{
            font-size: 10pt;
            text-decoration: none;
            color: #cfcfcf;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            font-weight: 500;

            // background-image: linear-gradient(#ffffff, $primary);
            // background-clip: text;
            // color: transparent;
        }
    }

    .middle-section{
        grid-area: middle-section;
        display: flex;
        align-items: center;
        justify-content: center;
        //border: 1px dashed teal;

        .btn1{
            display: inline-block;
            font-size: 9pt;
            user-select: none;
            color: $primary;
            text-decoration: none;
            
            &.margin-right{
                margin-right: 30px;
            }

            &:hover{
                //text-decoration: underline;
                color: #32c8ff;
            }
        }

        .btn2{
            user-select: none;

            display: inline-block;
            font-size: 9pt;
            background-color: $primary;
            color: $gray2;
            border-radius: 5px;
            padding: 3px 10px;

            &.margin-left{
                margin-left: 15px;
            }
            &.margin-right{
                margin-right: 15px;
            }

            &:hover{
                background-color: #32c8ff;
            }
        }

    }


    >.top-bar-right-menu{
        grid-area: right-section;
        padding: 0;
        margin: 0;

        white-space: nowrap;
        text-overflow: clip;
    }

    .menu-button{

        display: inline-flex;
        text-overflow: clip;
        white-space: nowrap;
        
        height: 100%;
        user-select: none;
        text-decoration: none;;

        
        align-items: center;
        justify-content: center;
        flex-direction: row;
        
        color: #afafaf;
        padding: 8px 10px;
        font-size: 9pt;
        //text-align: center;

        &.project-label{
            color: #ffffff;
        }

        &.color-primary{
            color: $primary;
        }

        .glyph{
            font-size: 11pt;
            color: #ffffff;
            margin-right: 5px;
        }

        &:hover{
            background-color: #afafaf;
            color: #606060;
            .glyph{
                color: #000000;
            }
        }
    }

    


    @media screen and (max-width: 600px) {
        .hide-on-mobile{
            display: none !important;
            visibility: collapse !important;
        }
    }

    @media screen and (max-width: 800px) {
        .hide-on-tablet{
            display: none !important;
            visibility: collapse !important;
        }
    }

    @media screen and (min-width: 800px) {
        .show-on-tablet{
            display: none !important;
            visibility: collapse !important;
        }
    }


}