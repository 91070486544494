@import "../../styles/colors.scss";

.image-swatch{
    //background-color: $gray2;
    
    font-size: 10pt;
    padding: 5px;
    user-select: none;

    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    grid-template-areas: 'image';

    &.static-height{
        height: 100px;
    }

    border: 1px solid #cfcfcf;
    position: relative;

    .inner-swatch{
        display: inline-block;
        position: absolute;
        left: 10px;
        top: 10px;
        width: 20px;
        height: 20px;
    }

    .btn-delete{
        display: none;
        //display: inline-flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -5px;
        top: -5px;
        width: 20px;
        height: 20px;
        background-color: #ffffff;
        border: 1px solid #cfcfcf;
        border-radius: 10px;
        color: #202020;
        &:hover{
            background-color: #202020;
            color: #cfcfcf;
        }
        
    }

    .img-container{
        
    }

    .img{
        height: 100px;
        width: 160px;
        //object-fit: contain;
        
        &.small{
            height: 100px;
            width: 160px;
        }

        &.med{
            height: 200px;
            width: 260px;
        }

        &.large{
            height: 300px;
            width: 360px;
        }

        &.xl{
            height: 500px;
            width: 560px;
        }

        &.xxl{
            height: 800px;
            width: 1260px;
        }
    }

    .fill{
        grid-area: image;
        border: 1px solid #cfcfcf;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        font-size: 8pt;
        background-color: #ffffff;
        //color: #404040;

        .swatch-name{
            font-weight: 500;
            margin-bottom: 5px;

            white-space: nowrap;
            text-overflow: clip;
            overflow: hidden;
            
            max-width: 160px;

            &.small{
                max-width: 160px;
            }

            &.med{
                max-width: 260px;
            }

            &.large{
                max-width: 360px;
            }

            &.xl{
                max-width: 560px;
            }

            &.xxl{
                max-width: 1060px;
            }
        }
    }

    &:not(.hide-hover){
        &:hover{
            background-color: $gray2;
            border: 1px solid #000000;
            .btn-delete{
                display: inline-flex;
            }
        }
    }

    &.sel{
        background-color: #000000;
        &:hover{
            background-color: #000000;
        }
    }
}