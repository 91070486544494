@import "../../../../styles/colors.scss";

.edit-sort-stage{


    .sort-entry{
        margin-bottom: 5px;

        display: grid;
        grid-template-columns: 1fr min-content min-content;

        .field-name{
            display: inline-block;
            user-select: none;
            border: 1px solid #cfcfcf;
            padding: 5px 10px;
            background-color: #ffffff;
            margin-right: 5px;

            &.new-entry{
                font-style: italic;;
            }

            &:hover{
                background-color: $primary;
                color: $primary-fg;
            }
        }
        .sort-dir{
            width: 80;
            &.invisible{
                opacity: 0;
            }
            
        }
        .actions{
            &.invisible{
                opacity: 0;
            }
        }
    }

    
}

.sort-stage-select-field-modal{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 20px;

    .top{
        .text1{
            border: 1px solid #cfcfcf;
            margin-bottom: 10px;
            width: 100%;
        }
    }
    
    .menu1{
        top: 0;
        left: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        border: 1px solid #cfcfcf;
        overflow-y: scroll;
    }
}