@import "../../styles/colors.scss";

.admin-page{

    display: grid;
    grid-template-rows: min-content min-content 1fr;
    grid-template-columns: 1fr min-content;
    grid-template-areas: 
        'topbar topbar'
        'jumbotron jumbotron'
        'main sidebar';

    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    .top-bar{
        grid-area: topbar;
    }

    .jumbotron{
        position: relative;
        grid-area: jumbotron;
        background-color: #ffffff; // $primary;
        color: #202020;
        border-bottom: 2px solid $primary;
        height: 150px;
        display: grid;
        grid-template-columns: min-content min-content 1fr min-content;
        grid-template-areas: 'avatar user-info activity space';
        font-size: 9pt;
        //color: #ffffff;
        //text-align: center;

        .avatar-area{
            grid-area: avatar;
            padding: 20px;

            .avatar{
                // position: absolute;
                // left: 20px;
                // top: 20px;
                width: 110px;
                height: 110px;
                background-color: #ffffff;
                border: 1px solid #cfcfcf;
                color: #202020;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 9pt;

                &.placeholder{
                    background-size: cover;
                    color: transparent;
                }
            }
        }

        .user-info{
            grid-area: user-info;
            padding: 20px 10px 20px 0;
            font-size: 9pt;
            width: 600px;
            

            .user-name{
                font-weight: 500;
                font-size: 10pt;
            }

            .member-since{
                color: #a0a0a0;
            }

            .team-info{

            }

            .achievements{
                margin-top: 10px;
            }
        }

        .empty-space{
            grid-area: space;
        }

        .activity-area{
            display: none;
            grid-area: activity;
            padding: 20px;
            width: 335px;

            .acol{
                font-size: 6.5pt;
            }

            .arow{
                display: block;
                font-size: 6.5pt;
                text-align: center;

            }

            .ablock{
                width: 10px;
                height: 10px;
                margin-bottom: 3px;
                border: 1px solid #cfcfcf;
            }
        }
    }

    .sidebar{
        grid-area: sidebar;
        width: 300px;
        border-left: 1px solid #cfcfcf;
        padding: 0;
        //text-align: center;
        
        overflow-y: auto;
    }

    >.main{
        grid-area: main;
        
        overflow-y: auto;
    }

    .projects-message{
        padding: 10px 0;

        &.error{
            color: red;
        }
    }


    .projects-table{

        width: 100%;
        //border: 1px solid #cfcfcf;
        border-top: 1px solid #cfcfcf;
        margin-top: 20px;
        row-gap: 0;
        column-gap: 0;
        border-spacing: 0;
        

        .project-label{

        }

        .project-modified{
            font-size: 8pt;
            color: rgba(0,0,0,0.4);
        }

        .client-label{
            padding: 10px;
            font-size: 9pt;
        }

        tr{ 
            td{
                padding: 0;
                border-bottom: 1px solid #cfcfcf;
                color: #202020;
                //border: 1px dashed red;

                .full-label{
                    //background-color: $gray2;
                    text-decoration: none;
                    display: block;
                    //border: 1px solid blue;
                    padding: 15px 20px;
                    color: #202020;

                    // &:hover{
                    //     background-color: $secondary;
                    //     color: #ffffff;
                    //     .project-modified{
                    //         color: rgba(255,255,255,0.6);
                    //     }
                    // }
                }


                &.actions{
                    width: 70px;
                    padding: 5px;
                    user-select: none;
                    //display: flex;
                    // align-items: center;
                    // justify-content: center;
                    //border: 1px solid purple;

                    
                }

                .action-btn{
                    
                    text-decoration: none;
                    padding: 5px 10px;
                    //background-color: $gray2;
                    &:hover{
                        background-color: #000000;
                        color: $gray2;
                    }
                }

                
            }
            &:hover{
                td{

                    .full-label{
                        //color: #ffffff;
                        .project-modified{
                            //color: rgba(255,255,255,0.6);
                        }
                    }


                    background-color: $tertiary;
                    color: $tertiary-fg;
                    .project-modified{
                        //color: rgba(255,255,255,0.6);
                    }
                }
            }
        }

    }
    
}